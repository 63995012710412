import { Paper, Card, Box, Typography, CardActionArea } from "@material-ui/core"
import React from "react"
import { PhotoFile, SeedlingPhotoFile } from "../types"

interface Props {
  value: PhotoFile | SeedlingPhotoFile | undefined,
  onClick: (e: any, v: string) => void,
}

export class PhotoPaper extends React.Component<Props> {
  render() {
    const { value, onClick } = this.props
    if (!value) {
      return (<></>)
    }
    const {
      previewUrl,
      id,
      name,
    } = value
    return (
      <Box p={ 1 }>
        <Paper elevation={ 0 }>
          <Card style={{
            position: 'relative',
            paddingTop: '100%',
          }}>
            <CardActionArea onClick={ (e) => onClick(e, id) } style={ {
              backgroundColor: '#dddddd',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            } } >
              <img
                style={ {
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  display: 'block',
                } }
                loading="lazy"
                alt="图片加载失败"
                src={`https://${previewUrl}?x-oss-process=image/resize,l_600`}
              />
            </CardActionArea>
          </Card>
          <Box display='flex'>
            <Box m={ 1 } flexGrow={ 1 }>
              <Typography variant="body1" align='left'>{ name }</Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    )
  }
}