import { gql } from '@apollo/client'
import { photoFileBase, photoFolderBase, seedlingPhotoFileBase } from './photoFolderService'

export const ACCOUNTREFRESHTOKEN = gql`
  query {
    accountRefreshToken
  }
`

export const SEND_SMS = gql`
  mutation($phone: String!, $category: String!) {
    sendSMS (
      phone: $phone,
      category: $category,
    )
  }
`

export const LOGIN = gql`
  mutation($phone: String!, $password: String!) {
    login (
      phone: $phone,
      password: $password,
    ) {
      token, refreshToken,
    }
  }
`

export const CREATE_ACCOUNT = gql`
  mutation($phone: String!, $password: String!, $smsCode: String!) {
    createAccount (
      phone: $phone,
      password: $password,
      smsCode: $smsCode,
    ) {
      token, refreshToken,
    }
  }
`
export const VALIDATE_SMS = gql`
  mutation($phone: String!, $category: String!, $smsCode: String!) {
    validateSMS (
      phone: $phone,
      category: $category,
      smsCode: $smsCode,
    )
  }
`

export const UPDATE_PASSWORD = gql`
  mutation($phone: String!, $password: String!, $smsCode: String!) {
    updatePassword(
      phone: $phone,
      password: $password,
      smsCode: $smsCode,
    ) {
      token, refreshToken,
    }
  }
`

export const UPDATE_ACCOUNT = gql`
  mutation($nickName: String!) {
    updateAccount(
      nickName: $nickName,
    ) {
      id,
      nickName,
    }
  }
`

export const GET_ACCOUNT = gql`
  ${photoFolderBase}
  ${photoFileBase}
  ${seedlingPhotoFileBase}
  query {
    getAccount {
      nickName,
      phone,
      category,
      expiredDate,
      photoFileCollectionList {
        ...PhotoFolderBase,
        tagList {
          name,
        },
        photoFileList(
          pageQuery: {
            sp: 0,
            ps: -1,
          },
        ) {
          ...PhotoFileBase,
          id,
          name,
          tagList {
            name,
          },
        },
      }
      seedlingPhotoFileCollectionList {
        ...SeedlingPhotoFileBase,
        tagList {
          name,
        },
      }
    }
  }
`

export const PHOTOFILEVIEWLISTHISTORY = gql`
  query($sp: Int!, $ps: Int!) {
    photoFileViewListHistory(
      pageQuery: { sp: $sp, ps: $ps },
    ) {
      photoFileList {
        id,
        name,
        previewUrl,
      },
      count,
      sp,
    }
  }
`

export const REPORT_MESSAGE = gql`
  mutation($context: String!) {
    reportMessage(context: $context)
  }
`
