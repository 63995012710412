import AccountStore from '../stores/accountStore';
import BasicUsageStore from '../stores/basicUsageStore';
import PhotoFolderStore from '../stores/photoFolderStore';
import { RecruitStore } from '../stores/recruitStore';
import UserInfoStore from '../stores/userInfoStore';
import { Stores } from '../types';

interface RootStores extends Stores {}

class RootStores {
  constructor() {
    this.basicUsageStore = new BasicUsageStore()
    this.accountStore = new AccountStore(this)
    this.userInfoStore = new UserInfoStore()
    this.recruitStore = new RecruitStore()
    this.photoFolderStore = new PhotoFolderStore()
  }
}

export default function createMobxStores() :Stores {
  const stores: Stores = new RootStores()
  return stores
}
