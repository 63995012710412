import { makeObservable, observable } from 'mobx'

export default class BasicUsageStore {

  constructor(){
    makeObservable(this)
  }

  @observable uid: String | null = null
  @observable name: String = ""

}
