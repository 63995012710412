import { inject, observer } from "mobx-react"
import React from "react"

import PhotoDialog from "../components/photoDialog"
import { PageProps, PhotoFile, Stores } from "../types"
import ProjectPaper from "../components/projectPaper"
import { Container, Box, Grid, Typography, Chip, createStyles, Theme, withStyles, WithStyles } from "@material-ui/core"
import { RouteComponentProps } from "react-router-dom"
import { PhotoPaper } from "../components/photoPaper"
import PhotoFolderStore from "../stores/photoFolderStore"
import AccountStore from "../stores/accountStore"

interface SelectedStores {
  photoFolderStore: PhotoFolderStore,
  accountStore: AccountStore,
}

const styles = (theme: Theme) => createStyles({
  root: {
    flex: 1,
  },
})

interface Props extends SelectedStores, PageProps, RouteComponentProps, WithStyles<typeof styles>{}

interface States {
  city: string | null
  projectCityList: Array<string>
  dialogState: boolean
  dialogList: PhotoFile[]
  dialogIndex: number
  dialogPhotoFolderId: string
}

@inject((stores: Stores): SelectedStores => ({
  photoFolderStore: stores.photoFolderStore,
  accountStore: stores.accountStore,
}))
@observer
class HomePage extends React.Component<Props> {

  state: States = {
    projectCityList: ['上海', '四川', '重庆'],
    city: '上海',
    dialogState: false,
    dialogList: [],
    dialogIndex: 0,
    dialogPhotoFolderId: '',
  }

  componentDidMount() {
    const { projectCityList } = this.state
    const { photoFolderStore } = this.props
    const city = projectCityList[0]
    photoFolderStore.initHotCityIdList([ city, '优质项目' ])
    photoFolderStore.initFreshIdList([ '每日分享' ])
    photoFolderStore.initRecommendPhotoFileIdList([])
  }

  handleChangeCity(e: string) {
    const { photoFolderStore } = this.props
    this.setState({ city: e })
    photoFolderStore.initHotCityIdList([e, '优质项目'])
  }

  handleSeedlingPhotoFileDialog = (e: any, v: string) => {
    const { photoFolderStore } = this.props
    const photoFileIndex = photoFolderStore.recommendPhotoFile.findIndex((item) => {
      return item?.id === v
    })
    if (photoFolderStore.recommendPhotoFile.length) {
      this.setState({
        dialogPhotoFolderId: '',
        dialogList: photoFolderStore.recommendPhotoFile,
        dialogIndex: photoFileIndex,
        dialogState: true,
      })
    }
  }

  handleHotCityDialog = (e: any, id: string, index: number) => {
    const { photoFolderStore } = this.props
    const photoFolder = photoFolderStore.hotCityPhotoFolder.find((item) => (item?.id === id))
    if (photoFolder) {
      this.setState({
        dialogPhotoFolderId: id,
        dialogList: photoFolder.photoFileList.map((item) => {
          item.name = photoFolder.name
          return item
        }),
        dialogIndex: index,
        dialogState: true,
      })
    }
  }

  handleFreshDialog = (e: any, id: string, index: number) => {
    const { photoFolderStore } = this.props
    const photoFolder = photoFolderStore.freshPhotoFolder.find((item) => (item?.id === id))
    if (photoFolder) {
      this.setState({
        dialogPhotoFolderId: id,
        dialogList: photoFolder.photoFileList.map((item) => {
          item.name = photoFolder.name
          return item
        }),
        dialogIndex: index,
        dialogState: true,
      })
    }
  }

  handleTruningPhotoDialog = (nextValue: number) => {
    const { photoFolderStore } = this.props
    const { dialogIndex, dialogList } = this.state
    const photoFile = dialogList[dialogIndex + nextValue]
    if (photoFile) {
      photoFolderStore.setDialogPhotoFileListIndex(photoFile.id)
      this.setState({
        dialogIndex: dialogIndex + nextValue,
      })
    }
  }

  handleDialogClose = () => {
    this.setState({ dialogList: [] })
  }

  handleCollection = (e: any, id: string, state: boolean) => {
    const { dialogList } = this.state
    const { photoFolderStore } = this.props
    photoFolderStore.setPhotoFileCollection(id, state)
    this.setState({ dialogList: dialogList.map((i) => {
      if (i.id === id) {
        i.isCollection = state
        i.collectionAmount += (state ? 1 : -1)
      }
      return i
    }) })
  }

  handleCollectionSeedling = (e: any, id: string, state: boolean) => {
    const { dialogList } = this.state
    const { photoFolderStore } = this.props
    photoFolderStore.setSeedlingPhotoFileCollection(id, state)
    dialogList.forEach((item) => {
      if (item.id === id) {
        item.isCollection = state
        item.collectionAmount += state ? 1 : -1
      }
    })
    this.setState({ dialogList })
  }

  handleDownload = async (e: any, id: string) => {
    const { photoFolderStore } = this.props
    return await photoFolderStore.getFileUrl(id)
  }

  handleDownloadSeedling = async (e: any, id: string) => {
    const { photoFolderStore } = this.props
    return await photoFolderStore.getSeedlingFileUrl(id)
  }

  render () {
    const {
      projectCityList,
      dialogList,
      dialogIndex,
      dialogPhotoFolderId,
    } = this.state
    const { photoFolderStore, classes, accountStore } = this.props
    const { hotCityPhotoFolder, freshPhotoFolder, recommendPhotoFile } = photoFolderStore
    return (
      <Box className={ classes.root }>
        <div style={ { width: '100%', paddingBottom: '40%', background: '#dddddd' } }/>
        <Container maxWidth='xl'>
          <Box paddingTop={ 2 }>
            <Box m={ 1 }>
              <Typography align='left' variant="h4" gutterBottom>热门城市</Typography>
            </Box>
            <Box m={ 1 }>
              <Grid container spacing={ 1 } justify="flex-start">
                {
                  projectCityList.map((item, index) => (
                    <Grid key={ index } item>
                      <Chip
                        label={ item }
                        onClick={ _ => this.handleChangeCity(item) }
                        color={ this.state.city === item ? "primary" : 'default' }
                      />
                    </Grid>
                  ))
                }
              </Grid>
            </Box>
            <Box m={ 1 }>
              <Grid container spacing={ 2 } justify={ hotCityPhotoFolder.length ? "flex-start" : "center"}>
                {
                  hotCityPhotoFolder.length ?
                  hotCityPhotoFolder.map((item, index) => (
                    <Grid item key={ index } xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 } xl={ 2 }>
                      <ProjectPaper
                        value={ item }
                        onClick={ this.handleHotCityDialog }
                      />
                    </Grid>
                  )) :
                  <Box marginTop={ 6 } marginBottom={ 6 }>
                    <Typography variant="h2" gutterBottom color="textSecondary">
                      当前城市项目暂缺
                    </Typography>
                  </Box>
                }
              </Grid>
            </Box>
          </Box>
          <Box paddingTop={ 2 }>
            <Box m={ 1 }>
              <Typography align='left' variant="h4" gutterBottom>推荐苗木</Typography>
            </Box>
            <Box m={ 1 }>
              <Grid container spacing={ 2 } justify={ recommendPhotoFile.length ? "flex-start" : "center"}>
                {
                  recommendPhotoFile.length ?
                  recommendPhotoFile.map((item, index) => (
                    <Grid item key={ index } xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 } xl={ 2 }>
                      <PhotoPaper
                        value={ item }
                        onClick={ this.handleSeedlingPhotoFileDialog }
                      />
                    </Grid>
                  )) :
                  <Box marginTop={ 6 } marginBottom={ 6 }>
                    <Typography variant="h2" gutterBottom color="textSecondary">
                      当前苗木暂缺
                    </Typography>
                  </Box>
                }
              </Grid>
            </Box>
          </Box>
          <Box paddingTop={ 2 }>
            <Box m={ 1 }>
              <Typography align='left' variant="h4" gutterBottom>最新项目</Typography>
            </Box>
            <Box m={ 1 }>
              <Grid container spacing={ 2 } justify={ freshPhotoFolder.length ? "flex-start" : "center"}>
                {
                  freshPhotoFolder.length ?
                  freshPhotoFolder.map((item, index) => (
                    <Grid item key={ index } xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 } xl={ 2 }>
                      <ProjectPaper
                        value={ item }
                        onClick={ this.handleFreshDialog }
                      />
                    </Grid>
                  )) :
                  <Box marginTop={ 6 } marginBottom={ 6 }>
                    <Typography variant="h2" gutterBottom color="textSecondary">
                      最新项目暂缺
                    </Typography>
                  </Box>
                }
              </Grid>
            </Box>
          </Box>
        </Container>
        {
          dialogPhotoFolderId
          ? <PhotoDialog
              accountStore={ accountStore }
              onDownload={ this.handleDownload }
              onNext={ () => this.handleTruningPhotoDialog(1) }
              onPrev={ () => this.handleTruningPhotoDialog(-1) }
              onClose={ this.handleDialogClose }
              onCollection={ this.handleCollection }
              open={ dialogList.length > 0 }
              value={ {
                index: dialogIndex,
                list: dialogList,
              } }
            />
          : <PhotoDialog
              accountStore={ accountStore }
              onDownload={ this.handleDownloadSeedling }
              onNext={ () => this.handleTruningPhotoDialog(1) }
              onPrev={ () => this.handleTruningPhotoDialog(-1) }
              onClose={ this.handleDialogClose }
              onCollection={ this.handleCollectionSeedling }
              open={ dialogList.length > 0 }
              value={ {
                index: dialogIndex,
                list: dialogList,
              } }
            />
        }
      </Box>
    )
  }
}

export default withStyles(styles)(HomePage)
