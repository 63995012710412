import {
  Link,
  Box,
  createStyles, Theme,
  Typography, WithStyles, withStyles,
} from "@material-ui/core"
import React from "react"
import { RouteComponentProps } from "react-router-dom"
import { LogoImg } from "../components/icons"
import ResetPasswordForm from "../components/resetPasswordForm"
import SignInForm from "../components/signInForm"
import SignUpForm from "../components/signUpForm"
import createHistory from "../main/createHistory"
import { gqlClient } from "../main/graphql"
import { getUrlParams, setUrlParams } from "../unit"

const styles = (theme: Theme) => createStyles({
  checkBox: {
    alignItems: 'flex-start',
  },
  logo: {
    margin: theme.spacing(0, 0, 2, 0),
  },
})

interface Props extends RouteComponentProps, WithStyles<typeof styles> {}

interface States {
  type: string,
  url: string,
}

class SignPage extends React.Component<Props, States> {

  state = {
    type: 'login',
    url: '',
  }

  componentDidMount() {
    const data = getUrlParams(['type', 'url'])
    this.setState({
      type: data.type || 'login',
      url: decodeURIComponent(data.url),
    })
  }

  handleSign = (_: any, type: string) => {
    const  { history } = this.props
    history.replace(`?${setUrlParams({ type })}`)
    this.setState({ type })
  }

  handleJump = async () => {
    const { url } = this.state
    await gqlClient.resetStore()
    createHistory.push(url ? url : '/')
  }

  render () {
    const { type } = this.state
    const { classes } = this.props
    return (
      <Box paddingTop={ 10 }>
        {
          (() => {
            switch (type) {
              case 'signUp':
              return (
                <SignUpForm onJump={ this.handleJump }>
                  <Box display="flex" flexDirection="column" alignItems="center" marginBottom={ 2 }>
                    <Link href="/">
                      <LogoImg className={ classes.logo } color="primary" fontSize={ 80 } />
                    </Link>
                    <Typography variant="h4" gutterBottom>创建你的账号</Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      已有账号？
                      <Link
                        onClick={ (_: any) => this.handleSign(_, 'login') }
                        variant="body1"
                      >立即登陆</Link>
                    </Typography>
                  </Box>
                </SignUpForm>
              )
              case 'reset':
              return (
                <ResetPasswordForm onJump={ this.handleJump }>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Link href="/">
                      <LogoImg className={ classes.logo } color="primary" fontSize={ 80 } />
                    </Link>
                    <Typography variant="h4" gutterBottom>找回密码</Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      已有账号？
                      <Link
                        onClick={ (_: any) => this.handleSign(_, 'login') }
                        variant="body1"
                      >登陆账号</Link>
                    </Typography>
                  </Box>
                </ResetPasswordForm>
              )
              default:
              return (
                <SignInForm
                  onSignReset={ (e: any) => this.handleSign(e, 'reset') }
                  onJump={ this.handleJump }
                >
                  <Box display="flex" flexDirection="column" alignItems="center" marginBottom={ 2 }>
                    <Link href="/">
                      <LogoImg className={ classes.logo } color="primary" fontSize={ 80 } />
                    </Link>
                    <Typography variant="h4" gutterBottom>欢迎回来</Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      还没有账号？
                      <Link
                        onClick={ (_: any) => this.handleSign(_, 'signUp') }
                        variant="body1"
                      >注册账号</Link>
                    </Typography>
                  </Box>
                </SignInForm>
              )
            }
          })()
        }
      </Box>
    )
  }
}

export default withStyles(styles)(SignPage)
