import {
  IconButton, Button, AppBar, Box, Slide,
  Toolbar, Theme, createStyles, withStyles, WithStyles,
} from '@material-ui/core'
import { AccountCircle } from '@material-ui/icons'
import * as React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { LogoImg } from './icons'

const styles = (theme: Theme) => createStyles({
  logoButton: {
    display: 'flex',
  },
})

interface MatchParams {
  [x: string]: string,
}

interface Props extends RouteComponentProps<MatchParams>, WithStyles<typeof styles>{
  trigger?: boolean,
  children: React.ReactNode,
}

interface States {
  tabIndex: string,
}

class NavTop extends React.Component<Props, States> {

  state: States = {
    tabIndex: '',
  }

  componentDidMount() {
    const { match, children } = this.props
    const childrenList = React.Children.toArray(children)
    if (!match.params.tab) {
      if (childrenList.length > 0 && React.isValidElement(childrenList[0])) {
        this.setState({ tabIndex: childrenList[0].props.tab })
      }
      return
    }
    const index = childrenList.findIndex(item => (
      React.isValidElement(item) ? item.props.tab === match.params.tab: false
    ))
    if (index >= 0) {
      this.setState({ tabIndex: match.params.tab })
    }
  }

  handleLink = (e: React.ChangeEvent<{}>, url: string) => {
    const { history } = this.props
    history.push({
      pathname: url,
    })
  }

  handleGoto = (e: React.ChangeEvent<{}>, tabIndex: string) => {
    const { history, children } = this.props
    const tabChild = React.Children.toArray(children).findIndex(item => (
      React.isValidElement(item) ? item.props.tab === tabIndex : false
    ))
    if (tabChild >= 0) {
      history.push(`/${tabIndex}`)
      this.setState({ tabIndex })
    }
  }

  render() {
    const { children, trigger } = this.props
    const token = localStorage.getItem('token')
    return (
      <>
        <Slide appear={ false } direction="down" in={ !trigger }>
          <AppBar>
            <Toolbar>
              <Button to="/" color="inherit" size="large" component={ Link }>
                <LogoImg color="primary" />
              </Button>
              <Button to="/find" color="inherit" size="large" component={ Link }>发现</Button>
              <Button to="/recruit" color="inherit" size="large" component={ Link }>招聘</Button>
              <Box flexGrow={ 1 }></Box>
              {
                token ?
                <IconButton to="/account" color="inherit" component={ Link }>
                  <AccountCircle />
                </IconButton> :
                <Button color="inherit" component={ Link } to="/sign">登陆</Button>
              }
            </Toolbar>
          </AppBar>
        </Slide>
        <Toolbar id="back-to-top-anchor"/>
        {
          children
        }
      </>
    );
  }
}

export default withStyles(styles)(NavTop)
