import { gql } from '@apollo/client'

export const recruitBase = gql`
  fragment RecruitBase on Recruit {
    id,
    company,
    location,
    position,
    description,
    releaseDate,
    contact,
    email,
  }
`

export const GET_RECRUIT_LIST = gql`
  ${recruitBase}
  query (
    $searchString: String,
    $tagList: [String!],
    $sp: Int!,
    $ps: Int!,
  ) {
    getRecuritList(
      searchString: $searchString,
      tagList: $tagList,
      pageQuery: {
        sp: $sp,
        ps: $ps,
      },
    ) {
      recruitList {
        ...RecruitBase,
        tagList {
          name,
        },
      },
      count,
    }
  }
`

export const getRecruit = gql`
  query ($id: String!) {
    getRecruit(
      id: $id,
    ) {
      id,
      company,
      location,
      position,
      description,
      releaseDate,
      contact,
      email,
      createDate,
      updateDate,
    }
  }
`
