import { Box, Container, Typography, ListItem, Link } from "@material-ui/core"
import React from "react"

export default class ServiceTermsPage extends React.Component {
  render() {
    return (
      <>
        <Container maxWidth="md">
          <Box marginTop={ 4 }>
            <Typography gutterBottom variant="h5" align="center">
              网站使用条款
            </Typography>
            <Typography gutterBottom align="right">更新时间：2021年7月5日</Typography>
            <br/>

            <Typography gutterBottom>
              欢迎使用EZpic产品及服务！
            </Typography>
            <Typography gutterBottom>
              这是一款面向景观素材软件互联网应用，
              该软件由上海魏玛软件技术服务有限公司提供，
              本服务条款是您与上海魏玛软件技术服务有限公司
              （下称“魏玛软件”）之间的协议。此条款在必要时将进行修订，
              且毋须另行通知。修订后的条款一旦在网页上公布即有效代替原来的服务条款。
              您承诺接受并遵守本协议的约定，届时您不应以未阅读本协议的内容或者未获得EZpic
              对您问询的解答等理由，主张本协议无效或要求撤销本协议。
            </Typography>

            <Typography gutterBottom variant="h6">
              一、注册及账号管理
            </Typography>
            <ListItem>
              <Typography>
                1、您确认，在您完成注册程序或以其他魏玛软件允许的方式实际使用服务时，
                您应当是具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人、
                法人或其他组织。若您不具备前述主体资格，请勿使用服务，
                否则您及您的监护人应承担因此而导致的一切后果，且魏玛软件有权注销
                （永久冻结）您的账户，并向您及您的监护人索偿。如您代表一家企业、
                组织机构或其他法律主体进行注册或以其他魏玛软件允许的方式实际使用本服务，
                则您声明和保证，您已经获得充分授权并有权代表该公司、
                组织机构或法律主体注册使用EZpic服务，并受本协议地约束；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                2、您可以使用您提供或确认的手机号或者EZpic允许的其它方式作为账号进行注册，
                您注册时应提交真实、准确、完整和反映当前情况的身份及其他相关信息，
                同时系统将为你自动分配一个账号，你可以对账号进行设置。你理解并同意，
                您承诺注册的账号名称、设置的昵称、头像和简介等信息中不得出现违法和不良信息，
                不得冒充他人，不得未经许可为他人注册，
                不得以可能导致其他用户误认的方式注册账号或设置昵称，
                不得使用可能侵犯他人权益的昵称（包括但不限于涉嫌商标权、名誉权侵权等），
                否则魏玛软件有权不予注册或停止服务并收回账号，因此产生的损失由您自行承担；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                3、您了解并同意，EZpic注册账号所有权归属于魏玛软件，
                注册完成后，您仅获得账号使用权。EZpic账号使用权仅归属于初始申请注册人，
                不得以任何方式转让或被提供予他人使用，否则，魏玛软件有权立即不经通知收回该账号，
                由此带来的因您使用本服务产生的全部数据、信息等被清空、丢失等的损失，您应自行承担；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                4、在您成功注册后，魏玛软件将根据您的身份要素识别您的身份和授权登录。
                “身份要素”包括但不限于您的账户名称、密码、短信校验码、手机号码、
                身份证件号码。您同意基于不同的终端以及您的使用习惯，
                魏玛软件可能采取不同的验证措施识别您的身份。例如您的账户在新设备首次登录的，
                魏玛软件可能通过密码加短信校验码或人脸验证的方式确认您的身份。
                请您务必妥善保管您的终端及账户、密码和/或身份要素信息，
                并对利用该账户和密码/身份信息所进行的一切活动
                （包括但不限于网上点击同意或提交各类规则协议或购买服务、分享资讯或图片）
                负全部责任。您承诺，在密码或账户遭到未获授权的使用，
                或者发生其他任何安全问题时，将立即通知魏玛软件，且您同意并确认，
                除法律另有规定外，魏玛软件不对上述情形产生的任何直接或间接的遗失或损害承担责任。
                除非有法律规定或司法裁定，且征得魏玛软件的同意，否则，您的账户、
                密码不得以任何方式转让、赠与或继承（与账户相关的财产权益除外）；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                5、您了解并同意，如您注册EZpic账号后连续超过12个月未登录，
                魏玛软件为优化管理等目的，有权回收该账号；
              </Typography>
            </ListItem>
            <br/>

            <Typography gutterBottom variant="h6">
              二、服务使用规范
            </Typography>
            <ListItem>
              <Typography>
                1、您充分了解并同意，EZpic仅为用户提供服务平台，
                您应自行对利用服务从事的所有行为及结果承担责任。相应地，您应了解，
                使用EZpic服务可能发生来自他人非法或不当行为（或信息）的风险，
                您应自行判断及行动，并自行承担相应的风险；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                2、您应自行承担因使用服务所产生的费用，包括但不限于上网流量费、通信服务费等；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                3、除非另有说明，本协议项下的服务只能用于非商业用途。
                您承诺不对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、
                转售或用于包括但不限于广告及任何其它商业目的；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                4、您承诺不会利用本服务进行任何违法或不当的活动，包括但不限于下列行为：
                <ListItem>
                  <Typography>
                    4.1、上载、传送或分享含有下列内容之一的信息；
                    <ListItem>
                      <Typography>
                        1）反对宪法所确定的基本原则的；
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        3）损害国家荣誉和利益的；
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        4）煽动民族仇恨、民族歧视、破坏民族团结的；
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        5）破坏国家宗教政策，宣扬邪教和封建迷信的；
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        6）散布谣言，扰乱社会秩序，破坏社会稳定的；
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        8）侮辱或者诽谤他人，侵害他人合法权利的；
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        9）含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、
                        粗俗、猥亵、或其它道德上令人反感的内容；
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        10）含有中国或其您所在国国家管辖法所适用的法律、法规、规章、
                        条例以及任何具有法律效力之规范所限制或禁止的其它内容的；
                      </Typography>
                    </ListItem>
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    4.2、冒充任何人或机构，或以虚伪不实的方式陈述或谎称与任何人或机构有关；
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    4.3、伪造标题或以其他方式操控识别资料，
                    使人误认为该内容为魏玛软件或其关联公司所传送；
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    4.4、将依据任何法律或合约或法定关系
                    （例如由于雇佣关系和依据保密合约所得知或揭露之内部资料、专属及机密资料）
                    知悉但无权传送之任何内容加以上载、传送或分享；
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    4.5、将涉嫌侵害他人权利（包括但不限于著作权、专利权、商标权、
                    商业秘密等知识产权）之内容上载、传送或分享；
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    4.6、跟踪或以其它方式骚扰他人, 或通过本服务向好友或其他用户发送大量信息；
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    4.7、将任何广告、推广信息、促销资料、“垃圾邮件”、“滥发信件”、“连锁信件”、
                    “直销”或其它任何形式的劝诱资料加以上载、传送或分享；
                    供前述目的使用的专用区域或专用功能除外；
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    4.8、因本服务并非为某些特定目的而设计，您不可将本服务用于包括但不限于核设施、
                    军事用途、医疗设施、交通通讯等重要领域。
                    如果因为软件或服务的原因导致上述操作失败而带来的人员伤亡、
                    财产损失和环境破坏等，魏玛软件不承担法律责任；
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    4.9、进行任何超出正常的好友或用户之间内部或外部信息沟通、交流等目的的行为；
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    4.10、出于超出正常好友或用户之间内部或外部信息沟通、交流等目的
                    （包括但不限于为发送广告、垃圾、骚扰或违法违规等信息的目的），
                    通过自己添加或诱导他人添加等任何方式使自己与其他用户形成好友关系
                    （好友关系包括但不限于单向好友关系和双向好友关系，下同）；
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    4.11、违反遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、
                    社会道德风尚和信息真实性等“七条底线”要求的行为；
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    4.12、从事任何违反中国法律、法规、规章、政策及规范性文件的行为；
                  </Typography>
                </ListItem>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                5、您承诺不对本软件和服务从事以下行为：
                <ListItem>
                  <Typography>
                    5.1、将有关干扰、破坏或限制任何计算机软件、
                    硬件或通讯设备功能的软件病毒或其他计算机代码、
                    档案和程序之资料，加以上载、张贴、发送电子邮件或以其他方式传送：
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    5.2、干扰或破坏本服务或与本服务相连线之服务器和网络，
                    或违反任何关于本服务连线网络之规定、程序、政策或规范；
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    5.3、通过修改或伪造软件运行中的指令、数据，增加、删减、
                    变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，
                    无论这些行为是否为商业目的；
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    5.4、通过非魏玛软件开发、授权的第三方软件、插件、外挂、系统，
                    登录或使用软件及服务，或制作、发布、传播上述工具；
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    5.5、自行、授权他人或利用第三方软件对本软件及其组件、模块、数据等进行干扰；
                  </Typography>
                </ListItem>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                6、您承诺，使用EZpic服务时您将严格遵守本协议；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                7、您同意并接受魏玛软件有权对您使用服务的情况进行审查、监督并采取相应行动，
                包括但不限于删除信息、中止或终止服务，及向有关机关报告。
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                8、您承诺不以任何形式使用本服务侵犯魏玛软件的商业利益，
                或从事任何可能对魏玛软件造成损害或不利于魏玛软件的行为。
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                9、您了解并同意，在您使用EZpic服务过程中，
                魏玛软件及其关联公司自行或由其他第三方合作伙伴向您发送广告、推广或宣传信息
                （包括商业或非商业信息）的，魏玛软件将依照法律的规定对广告商履行相关义务，
                您应当自行判断广告信息的真实性可可靠性并为自己的判断行为负责；
                除法律明确规定外，您因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失
                或损害，魏玛软件不承担责任。
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                10、如您在EZpic中使用支付平台向您通过的红包功能或收付、转账功能的，应当依照法律、
                法规等规范性文件的规定开展，不得以非法方式使用红包功能或收付、转账功能，
                也不得将红包功能或支付、转账功能用于实施非法活动，例如实施赌博、非法吸收公众存款、
                集资诈骗、开展传销等违法犯罪行为，或为违法犯罪行为提供协助。
                因此给魏玛软件或他人造成损失的，您应当予以赔偿。
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                11、您充分了解并同意，您必须为自己注册帐号下的一切行为负责，
                包括您所发表的任何内容以及由此产生的任何后果。
                您应对使用本服务时接触到的内容自行加以判断，并承担因使用内容而引起的所有风险，
                包括因对内容的正确性、完整性或实用性的依赖而产生的风险。
                魏玛软件无法且不会对您因前述风险而导致的任何损失或损害承担责任。
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                12、如果魏玛软件发现或收到他人举报您有违反本协议约定的，
                魏玛软件有权依照相关法律法规的规定对相关举报内容核实、
                转通知以及删除、屏蔽等措施，以及采取包括但不限于收回账号，
                限制、暂停、终止您使用部分或全部本服务，追究法律责任等措施。
              </Typography>
            </ListItem>
            <br/>

            <Typography gutterBottom variant="h6">
              三、服务费用
            </Typography>
            <Typography gutterBottom>
              如果您有意愿使用魏玛软件付费服务，这将表示您同意支付其中的所有费用。
              在您提交使用付费产品的申请后，魏玛软件将提供经认可的第三方在线服务机构的支付方式，
              并要求您支付相关费用。成功支付后，表明您已经获得使用付费服务的权利并且已经达成此项交易，
              除非因魏玛软件的原因导致服务无法正常提供，否则魏玛软件将不退还您已经支付的服务费。
              此外，由于您违反了《隐私协议》和《网站使用条款》的相关规定而导致帐户不可用，
              魏玛软件将不会退还付费产品在未使用期间的服务费。
            </Typography>
            <ListItem>
              <Typography>
                1、本服务的任何免费试用或免费功能和服务不应视为魏玛软件放弃后续收费的权利。
                魏玛软件有权提前7天在EZpic官网上和/或具体EZpic服务应用内以公告的形式通知
                您收费标准及收费方式。若您继续使用相关EZpic服务，
                则需按魏玛软件公布的收费标准支付相应费用。
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                2、所有费用需通过魏玛软件接受的支付方式事先支付。前述使用费不包含其它任何税款、
                费用或相关汇款等支出，否则您应补足付款或自行支付该费用。
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                3、魏玛软件有权根据实际情况提前7天单方调整费用标准及收费方式，并以公告形式通知您，
                而无需获得您的事先同意，如您不同意收费应当立即停止服务的使用，
                否则使用则视为您已同意并应当缴纳费用。
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                4、您应当自行支付使用本服务可能产生的上网费以及其他第三方收取的通讯费、信息费等。
              </Typography>
            </ListItem>
            <br/>

            <Typography gutterBottom variant="h6">
              四、特别授权
            </Typography>
            <ListItem>
              <Typography>
                1、当您向魏玛软件及关联公司作出任何形式的承诺，且相关公司已确认您违反了该承诺，
                则魏玛软件有权立即按您的承诺约定的方式对您的账户采取限制措施，
                包括但不限于中止或终止向您提供服务，并公示相关公司确认的您的违约情况。
                您了解并同意，魏玛软件无须就相关确认与您核对事实，或另行征得您的同意，
                且魏玛软件无须就此限制措施或公示行为向您承担任何的责任；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                2、一旦您违反本协议，或与魏玛软件签订的其他协议的约定，
                魏玛软件有权以任何方式通知魏玛软件关联公司，要求其对您的权益采取限制措施，
                包括但不限于要求魏玛软件关联公司中止、终止对您提供部分或全部服务，
                且在其经营或实际控制的任何网站公示您的违约情况；
              </Typography>
            </ListItem>
            <br/>

            <Typography gutterBottom variant="h6">
              五、服务中止或终止
            </Typography>
            <ListItem>
              <Typography>
                1、鉴于互联网服务的特殊性，魏玛软件有权对服务内容进行变更，也可能会中断、
                中止或终止全部或者任一部分的服务，包括免费服务和/或收费服务，
                并在EZpic官网上以公示的形式通知您；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                2、您了解并同意，魏玛软件可能定期或不定期地对提供网络服务的平台设备、
                设施和软硬件进行维护或检修，如因此类情况而造成收费服务在合理时间内中止，
                魏玛软件无需承担责任；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                3、如存在下列违约情形之一，魏玛软件有权立即对您中止或终止服务，并要求您赔偿损失：
                <ListItem>
                  <Typography>
                    3.1、用户违反第一条之注册义务；
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    3.2、用户使用收费网络服务时未按规定支付相应服务费；
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    3.3、用户违反第二条服务使用规范之规定；
                  </Typography>
                </ListItem>
              </Typography>
            </ListItem>
            <br/>

            <Typography gutterBottom variant="h6">
              六、隐私保护
            </Typography>
            <ListItem>
              <Typography>
                1、您在EZpic服务注册的账户具有密码保护功能，以确保您基本信息资料的安全，
                请您妥善保管账户及密码信息；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                2、魏玛软件努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，
                使您存储在EZpic中的信息和通信内容不会被泄漏、毁损或者丢失，包括但不限于SSL、
                信息加密存储、数据中心的访问控制。
                魏玛软件对可能接触到信息的员工或外包人员也采取了严格管理，
                包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，
                监控他们的操作情况等措施。EZpic会按现有技术提供相应的安全措施来保护您的信息，
                提供合理的安全保障，EZpic将在任何时候尽力做到使您的信息不被泄漏、毁损或丢失，
                但同时也请您注意在信息网络上不存在绝对完善的安全措施，请妥善保管好相关信息；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                3、您应当保管好终端、账号及密码，并妥善保管相关信息和内容。
                因您自身原因导致的数据丢失或被盗以及在本软件及服务中相关数据的删除或储存失败
                的责任由您自行承担；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                4、其他隐私条款见<Link href="/privacy-policy" target="_blank">《隐私协议》</Link>；
              </Typography>
            </ListItem>
            <br/>

            <Typography gutterBottom variant="h6">
              七、知识产权
            </Typography>
            <ListItem>
              <Typography>
                1、您了解及同意，除非魏玛软件另行声明，本协议项下服务包含的所有产品、
                技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图片、照片、音频、
                视频、图表、色彩、版面设计、电子文档）的所有知识产权（包括但不限于版权、商标权、
                专利权、商业秘密等）及相关权利均归魏玛软件或其关联公司所有；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                2、您应保证，除非取得魏玛软件公司书面授权，对于上述权利您不得（并不得允许任何第三人）
                实施包括但不限于出租、出借、出售、散布、复制、修改、转载、汇编、发表、出版、还原工程、
                反向汇编、反向编译，或以其它方式发现原始码等的行为；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                3、魏玛软件提供的网络服务中包含的任何文本、图片、图形、音频、字形字体、
                设计素材和/或视频资料均受版权、商标和/或其它财产所有权法律的保护，
                除本协议另有约定外，未经相关权利人同意，上述资料均不得在任何媒体直接或间接发布、
                播放、出于播放或发布目的而改写或再发行，或者被用于其他任何商业目的。
                魏玛软件不就由上述资料产生或在传送或递交全部或部分上述资料过程中产生的延误、
                不准确、错误和遗漏或从中产生或由此产生的任何损害赔偿，以任何形式，
                向用户或任何第三方负责；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                4、魏玛软件所有的产品、服务、技术与所有应用程序或其组件/功能/名称
                （以下或简称“技术服务”）的知识产权均归属于魏玛软件所有或归其权利人所有；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                5、您理解并同意授权魏玛软件公司在宣传和推广中使用您的名称、商标、标识，
                但仅限于表明您属于魏玛软件的客户或合作伙伴；
              </Typography>
            </ListItem>
            <br/>

            <Typography gutterBottom variant="h6">
              八、有限责任
            </Typography>
            <ListItem>
              <Typography>
                1、服务将按"现状"和按"可得到"的状态提供。
                魏玛软件在此明确声明对服务不作任何明示或暗示的保证，包括但不限于对服务的可适用性，
                没有错误或疏漏，持续性，准确性，可靠性，适用于某一特定用途之类的保证，声明或承诺；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                2、EZpic对服务所涉的技术和信息的有效性，准确性，正确性，可靠性，质量，稳定，
                完整和及时性均不作承诺和保证；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                3、不论在何种情况下，EZpic均不对由于Internet连接故障，电脑，通讯或其他系统的故障，
                电力故障，罢工，劳动争议，暴乱，起义，骚乱，生产力或生产资料不足，火灾，洪水，
                风暴，爆炸，不可抗力，战争，政府行为，国际、
                国内法院的命令或第三方的不作为而造成的不能服务或延迟服务承担责任；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                4、不论是否可以预见，不论是源于何种形式的行为，魏玛软件不对由以下原因造成的任何特别的，
                直接的，间接的，惩罚性的，突发性的或有因果关系的损害或其他任何损害
                （包括但不限于利润或利息的损失，营业中止，资料灭失）承担责任：
                <ListItem>
                  <Typography>
                    4.1、使用或不能使用服务；
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    4.2、通过服务购买或获取任何产品，样品，数据，信息或进行交易等，
                    或其他可替代上述行为的行为而产生的费用；
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    4.3、未经授权的存取或修改数据或数据的传输；
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    4.4、第三方通过服务所作的陈述或行为；
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    4.5、其它与服务相关事件，包括疏忽等，所造成的损害；
                  </Typography>
                </ListItem>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                5、您充分了解并同意，鉴于互联网体制及环境的特殊性，
                您在服务中分享的信息及个人资料有可能会被他人复制、转载、擅改或做其它非法用途；
                您在此已充分意识此类风险的存在，并确认此等风险应完全由您自行承担，
                魏玛软件对此不承担任何责任；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                6、您了解并同意，在使用服务过程中可能存在来自任何他人的包括威胁性的、
                诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯（包括知识产权）
                及匿名或冒名的信息的风险，基于服务使用规范所述，该等风险应由您自行承担，
                魏玛软件对此不承担任何责任；
              </Typography>
            </ListItem>
            <br/>

            <Typography gutterBottom variant="h6">
              九、有限责任
            </Typography>
            <ListItem>
              <Typography>
                1、如果魏玛软件发现或收到他人举报您有违反本协议约定的，
                魏玛软件有权不经通知随时对相关内容进行删除、屏蔽，并采取包括但不限于限制、
                暂停、终止您使用EZpic账号及服务，限制、暂停、终止您使用部分或全部本服务
                （包括但不限于解除与团体或成员的所属关系，删除通信录、限制增加成员、
                限制发送消息或添加好友等），追究法律责任等措施；
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                2、您同意，由于您通过服务上载、传送或分享之信息、使用本服务其他功能、
                违反本协议、或您侵害他人任何权利因而衍生或导致任何第三人向魏玛软件
                及其关联公司提出任何索赔或请求，或魏玛软件及其关联公司因此而发生任何损失，
                您同意将足额进行赔偿（包括但不限于合理律师费）；
              </Typography>
            </ListItem>
            <br/>

            <Typography gutterBottom variant="h6">
              十、其他
            </Typography>
            <Typography gutterBottom>
              如果您对本协议或本服务有意见或建议，可与魏玛软件客户服务部门联系，
              联系方式为：ezland@weimargroup.com，魏玛软件会给予您必要的帮助。
            </Typography>
            <br/>
          </Box>
        </Container>
      </>
    )
  }
}