import {
  Container, Box, Grid,
  Divider, withStyles,
  createStyles, Theme, WithStyles,
  Typography,
} from "@material-ui/core"
import React from "react"
import SelectMenu from "../components/selectMenu"
import { PageProps, Recruit, Stores } from "../types"
import PhotoSearch from "../components/photoSearch"
import RecruitPaper from "../components/recruitPaper"
import RecruitDialog from "../components/recruitDialog"
import { inject, observer } from "mobx-react"
import { RecruitStore } from "../stores/recruitStore"
import RecruitInfiniteScroll from "./recruitPage/recruitInfiniteScroll"
import AccountStore from "../stores/accountStore"

type SearchRecruitType = '搜索职位'
type TagKey = '地区'

interface Option {
  key: string,
  text: string,
  value: string,
}

interface SelectedStores {
  recruitStore: RecruitStore
  accountStore: AccountStore
}

interface Props extends SelectedStores, PageProps, WithStyles<typeof styles> {}

interface States {
  searchString: string
  tagObject: {
    [K in SearchRecruitType]: {
      [K in TagKey]?: string
    }
  }
  searchRecruitType: SearchRecruitType
  dialogState: boolean,
  dialogList: Recruit[]
  dialogIndex: number
  dialogPhotoFolderId: string
}

const styles = (theme: Theme) => createStyles({
  root: {
    flex: 1,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  searchIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginLeft: theme.spacing(2)
  },
  scrollTooltip: {
    margin: theme.spacing(6, 0),
    textAlign: 'center',
  },
})

@inject((stores: Stores): SelectedStores => ({
  recruitStore: stores.recruitStore,
  accountStore: stores.accountStore,
}))
@observer
class RecruitPage extends React.Component<Props, States> {

  state: States = {
    searchString: '',
    searchRecruitType: '搜索职位',
    tagObject: { '搜索职位': {} },
    dialogState: false,
    dialogList: [],
    dialogIndex: 0,
    dialogPhotoFolderId: '',
  }

  componentDidMount() {
    const { recruitStore } = this.props
    recruitStore.initFindRecruitIdList([])
  }

  handleSearchString = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchString: e.target.value })
  }

  handleTagOption = async (e: any, searchPhotoType: SearchRecruitType, tagKey: TagKey, tag: Option) => {
    const { searchString, tagObject } = this.state
    const { recruitStore } = this.props
    tagObject[searchPhotoType][tagKey] = tag.value
    const tagList = [...Object.values(tagObject[searchPhotoType])].filter((i): i is string => !!i)
    if (searchPhotoType === '搜索职位') {
      recruitStore.initFindRecruitIdList(tagList, searchString)
    }
    this.setState({ tagObject })
  }

  handleSearch = (searchPhotoType: SearchRecruitType) => {
    const { searchString, tagObject } = this.state
    const { recruitStore } = this.props
    const tagList = [...Object.values(tagObject[searchPhotoType])].filter((i): i is string => !!i)
    if (searchPhotoType === '搜索职位') {
      recruitStore.initFindRecruitIdList(tagList, searchString)
    }
    this.setState({ tagObject })
  }

  handleFetchMoreData = () => {
    const { searchString, searchRecruitType, tagObject } = this.state
    const { recruitStore } = this.props
    const tagList = Object.values(tagObject[searchRecruitType]).filter((i): i is string => !!i)
    if (searchRecruitType === '搜索职位') {
      recruitStore.nextFindRecruitIdList(tagList, searchString)
    }
  }

  handleRecruitDialog = (e: any, v: string) => {
    const { recruitStore } = this.props
    const findRecruitIndex = recruitStore.findRecruit.findIndex((item) => {
      return item?.id === v
    })
    if (recruitStore.findRecruit.length) {
      this.setState({
        dialogList: recruitStore.findRecruit,
        dialogIndex: findRecruitIndex,
        dialogState: true,
      })
    }
  }

  handleCloseRecruitDialog = () => {
    this.setState({ dialogState: false, dialogList: [] })
  }

  render() {
    const { tagObject, dialogState, dialogList, dialogIndex, searchString, searchRecruitType } = this.state
    const { classes, recruitStore, accountStore } = this.props
    const { findRecruit, findRecruitCount } = recruitStore
    return (
      <Box className={ classes.root }>
        <Box paddingTop={ 10 } paddingBottom={ 8 }>
          <Container maxWidth="md">
            <PhotoSearch actionList={ [
                { key: '搜索职位', text: '搜索职位', value: '搜索职位', onClick: () => this.handleSearch('搜索职位') },
              ] }
              value={ searchString }
              onChange={ this.handleSearchString }
            />
          </Container>
        </Box>
        {
          searchRecruitType === '搜索职位' &&
          <Container maxWidth="xl">
            <Box display="flex" justifyContent="space-between">
              <Box>
                <SelectMenu
                  value={ tagObject['搜索职位']['地区'] || '' }
                  onChange={ (e, v) => this.handleTagOption(e, '搜索职位', '地区', v) }
                  data={ [
                    { key: '全国',  text: '全国', value: '' },
                  ] }
                />
              </Box>
            </Box>
            <Divider className={ classes.divider } />
            <RecruitInfiniteScroll
              dataLength={ findRecruit.length }
              next={ this.handleFetchMoreData }
              hasMore={ findRecruit.length < findRecruitCount }
              empty={
                <Box marginTop={ 6 } marginBottom={ 6 } textAlign="center">
                  <Typography variant="h2" gutterBottom color="textSecondary">
                  您搜索的职位暂未发布
                  </Typography>
                </Box>
              }
            >
              <Grid container spacing={ 2 }>
                {
                  findRecruit.map((item, index) => (
                    <Grid key={ index } item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 } xl={ 2 }>
                      <RecruitPaper
                        value={ item }
                        onClick={ this.handleRecruitDialog }
                      />
                    </Grid>
                  ))
                }
              </Grid>
            </RecruitInfiniteScroll>
          </Container>
        }

        <RecruitDialog
          accountStore={ accountStore }
          onClose={ this.handleCloseRecruitDialog }
          open={ dialogState }
          value={ {
            index: dialogIndex,
            list: dialogList,
          } }
        />
      </Box>
    )
  }
}

export default withStyles(styles)(RecruitPage)
