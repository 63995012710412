import React from "react"
import { Container } from "@material-ui/core"
import SignUpFormik from "./signUpFormik"

interface Props {
  onJump: () => void,
}

export default class SignUpForm extends React.Component<Props> {

  state = {
    registerStepIndex: 0,
  }

  handleChange = () => {
    const { registerStepIndex } = this.state
    this.setState({
      registerStepIndex: registerStepIndex + 1,
    })
  }

  render() {
    const { registerStepIndex } = this.state
    const { children } = this.props
    return (
      <Container maxWidth="xs">
        <SignUpFormik
          registerStepIndex={ registerStepIndex }
          onNextPage={ this.handleChange }
          { ...this.props }
        >
          { children }
        </SignUpFormik>
      </Container>
    )
  }
}