import {
  AppBar, Box, Container,
  Link, createStyles, IconButton, Theme,
  Toolbar, Typography, withStyles,
  WithStyles, Popover,
} from '@material-ui/core'
import * as React from 'react'
import { QqIcon, WechatIcon } from './icons'

const styles = (theme: Theme) => createStyles({
  footBox: {
    marginTop: theme.spacing(10),
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  link: {
    "&:hover": {
      color: 'inherit',
      opacity: 0.5,
    }
  },
})

interface Props extends WithStyles<typeof styles> {}

class Footer extends React.Component<Props> {

  state = {
    open: false,
    anchorEl: null,
    node: null,
  }

  handleOpen = (e: React.MouseEvent<HTMLButtonElement>, node: React.ReactNode) => {
    this.setState({ open: true, anchorEl: e.currentTarget, node })
  }

  handleClose = () => {
    this.setState({ open: false, anchorEl: null })
  }

  handleOpenPage = (_: any, url: string) => {
    const w = window.open('about:blank')
    if (w) {
      w.location.href = url
    }
  }

  render() {
    const { open, anchorEl, node } = this.state
    const { classes } = this.props
    return (
      <>
        <AppBar className={ classes.footBox } position="static" color="primary">
          <Container maxWidth="lg">
            <Toolbar>
              <Box flexGrow={ 1 } paddingRight={ 1 }>
                <Typography variant="body1" color="inherit">
                  版权所有 © 2017-2021 上海魏玛软件技术服务有限公司
                </Typography>
                <Link
                  href="https://beian.miit.gov.cn/"
                  variant="body1"
                  color="inherit"
                >
                  沪ICP备12033836号-5
                </Link>
              </Box>
              <Box>
                <IconButton color="inherit" onClick={ (e) => this.handleOpen(e, (
                  <img alt="" src="/wechat_qr_code.jpg" height={ 240 } width={ 240 } />
                )) }>
                  <WechatIcon />
                </IconButton>
                <IconButton color="inherit" onClick={ (e) => this.handleOpen(e, (
                  <img alt="" src="/qq_qr_code.jpg" height={ 293 } width={ 240 } />
                )) }>
                  <QqIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Popover
          open={ open }
          anchorEl={ anchorEl }
          onClose={ this.handleClose }
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Box p={ 2 }>
            { node }
          </Box>
        </Popover>
      </>
    )
  }
}

export default withStyles(styles)(Footer)
