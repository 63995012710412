import { Grid, Button, createStyles, Divider, Paper, Theme, WithStyles, withStyles, InputBase, withWidth, Box } from "@material-ui/core"
import React from "react"
import { Search } from '@material-ui/icons/'
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"
import SelectMenu from "../components/selectMenu"

const styles = (theme: Theme) => createStyles({
  searchIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  button: {
    margin: theme.spacing(1),
  },
})

interface Action {
  key: string
  text: string
  value: string
  onClick: () => void
}

interface Props extends WithStyles<typeof styles> {
  actionList: Action[]
  actionIndex?: number
  value: string
  onChange: (e: any) => void
  width?: Breakpoint,
}

interface States {
  actionIndex: number
}

class PhotoSearch extends React.Component<Props, States> {

  state: States = {
    actionIndex: 0,
  }

  componentDidMount() {
    const { actionIndex } = this.props
    if (actionIndex) {
      this.setState({ actionIndex })
    }
  }

  handleAction = (index: number, callback: () => void) => {
    this.setState({ actionIndex: index })
    callback()
  }

  handleSearchOption = (e: any, v: string | undefined) => {
    const { actionList } = this.props
    const actionIndex = actionList.findIndex((item) => item.value === v) || 0
    this.handleAction(actionIndex, actionList[actionIndex].onClick)
  }

  handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const { actionIndex } = this.state
    const { actionList } = this.props
    this.handleAction(actionIndex, actionList[actionIndex].onClick)
  }

  render() {
    const { actionIndex } = this.state
    const { classes, actionList, value, width, onChange } = this.props
    return (
      <Box onSubmit={ this.handleSubmit }>
        <Paper
          variant="outlined"
          component="form"
          style={{ padding: '2px 4px', display: 'flex', alignItems: 'center' }}
        >
        {
          width === 'xs'
          ? <>
              <Grid container alignItems="center">
                <Search className={ classes.searchIcon } />
                <InputBase
                  style={ { flex: 1, fontSize: '1.5em' } }
                  placeholder="搜索"
                  value={ value }
                  onChange={ onChange }
                />
                <Divider className={ classes.divider } orientation="vertical" />
                <SelectMenu
                  value={ actionList[actionIndex].value }
                  onChange={ (e, v) => this.handleSearchOption(e, v.value) }
                  data={ actionList.map((item) => ({
                    key: item.key,
                    text: item.text,
                    value: item.value,
                  })) }
                />
              </Grid>
            </>
          : <>
              <Search className={ classes.searchIcon } />
              <InputBase
                style={ { flex: 1, fontSize: '1.5em' } }
                placeholder="搜索"
                value={ value }
                onChange={ onChange }
              />
              <Divider className={ classes.divider } orientation="vertical" />
              {
                actionList.map((item, index) => (
                  <Button
                    key={ item.key }
                    color="primary"
                    variant={ index === actionIndex ? 'contained' : 'text'}
                    className={ classes.button }
                    onClick={ () => this.handleAction(index, item.onClick) }
                  >
                    { item.text }
                  </Button>
                ))
              }
            </>

        }
        </Paper>
      </Box>
    )
  }
}

export default withStyles(styles)(withWidth()(PhotoSearch))
