import React from "react"
import {
  Checkbox, Box, Button, createStyles,
  FormControl, FormControlLabel, Link, Step,
  StepLabel, Stepper, TextField, Theme,
  Typography, WithStyles, withStyles,
  FormHelperText, IconButton, InputAdornment, Snackbar,
} from "@material-ui/core"
import { withFormik, FormikProps, Form } from "formik"
import * as Yup from 'yup'
import { Visibility, VisibilityOff, Close } from "@material-ui/icons"
import moment from 'moment'
import SMSTextField from "./smsTextField"
import AccountStore from "../stores/accountStore"
import { gqlClient } from "../main/graphql"
import { SEND_SMS, CREATE_ACCOUNT, UPDATE_ACCOUNT } from "../service/accountService"

const styles = (theme: Theme) => createStyles({
  stepper: {
    marginTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
  },
  smsCodeInput: {
    marginTop: theme.spacing(6),
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: theme.spacing(8),
    },
  },
  smsCodeFontSize: {
    fontSize: theme.spacing(5),
    textAlign: 'center',
  },
  textAlign: {
    textAlign: 'center',
  },
})

interface FormValues {
  phone: string,
  password: string,
  agree: boolean,
  smsCode: string,
  smsCode0: string,
  smsCode1: string,
  smsCode2: string,
  smsCode3: string,
  nickName: string,
}

interface FormProps {
  registerStepIndex: number,
  onNextPage: () => void,
  onJump: () => void,
}

interface SelectedStores {
  accountStore?: AccountStore
}

interface Props extends SelectedStores, FormProps, WithStyles<typeof styles>, FormikProps<FormValues> {}

interface Statics {
  showPassword: boolean,
  nextStepButtonLabel: string,
  snackBarMessage: string,
  snackBarState: boolean,
}

class SignUpFormik extends React.Component<Props, Statics> {
  [x: string]: any
  state = {
    showPassword: false,
    nextStepButtonLabel: '稍后完善资料',
    snackBarMessage: '',
    snackBarState: false,
  }

  handleClickShowPassword = () => {
    const { showPassword } = this.state
    this.setState({
      showPassword: !showPassword,
    })
  }

  handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { handleChange } = this.props
    e.target.value = ''
    handleChange(e)
  }

  handleInputSMSCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { setFieldValue } = this.props
    setFieldValue(e.target.name, e.target.value)
  }

  handleInputUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { handleChange } = this.props
    if (e.target.value) {
      this.setState({ nextStepButtonLabel: '提交' })
    } else {
      this.setState({ nextStepButtonLabel: '稍后完善资料' })
    }
    handleChange(e)
  }

  handleResendSMS = async () => {
    const phone = this.props.getFieldProps('phone').value
    const { errors } = await gqlClient.mutate({
      mutation: SEND_SMS,
      variables: { phone, category: 'signUp' },
      fetchPolicy: 'no-cache',
    })
    if (errors) {
      this.setState({
        snackBarMessage: errors[0].message,
        snackBarState: true,
      })
    } else {
      this.setState({
        snackBarMessage: '验证码已发送',
        snackBarState: true,
      })
    }
  }

  handleFinish = () => {
    this.props.onJump()
  }

  handleSnackBarClose = () => {
    this.setState({ snackBarState: false })
  }

  render() {
    const { showPassword, nextStepButtonLabel, snackBarMessage, snackBarState } = this.state
    const {
      classes,
      children,
      errors,
      touched,
      values,
      handleChange,
      isSubmitting,
      registerStepIndex,
    } = this.props
    return (
      <>
        <Box
          component={ Form }
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
        { children }
        {
          (() => {
            switch (registerStepIndex) {
              case 0:
              return (
                <>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      name="phone"
                      label="手机号"
                      type="text"
                      value={ values.phone }
                      onChange={ handleChange }
                      autoComplete="username"
                      variant="filled"
                      error={ touched.phone && Boolean(errors.phone) }
                      helperText={ (touched.phone && errors.phone) || ' ' }
                    />
                  </FormControl>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      name="password"
                      label="密码"
                      type={ showPassword ? "text" : "password" }
                      value={ values.password }
                      onChange={ handleChange }
                      autoComplete="new-password"
                      variant="filled"
                      error={ touched.password && Boolean(errors.password) }
                      helperText={ (touched.password && errors.password) || ' ' }
                      InputProps={ {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={ this.handleClickShowPassword }
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      } }
                    />
                  </FormControl>
                  <FormControl margin="normal" fullWidth>
                    <FormControlLabel
                      label={
                        <Typography variant="subtitle2">
                          本人确认已仔细阅读并充分理解
                          <Link href="/privacy-policy" target="_blank">《隐私协议》</Link>
                          与
                          <Link href="/service-terms" target="_blank">《网站使用条款》</Link>
                          的全部内容，同意接受上述协议的全部内容
                        </Typography>
                      }
                      control={
                        <Checkbox
                          name="agree"
                          value={ values.agree }
                          onChange={ handleChange }
                          color="primary"
                        />
                      }
                    />
                    <FormHelperText
                      error={ touched.agree && Boolean(errors.agree) }
                    >{ (touched.agree && errors.agree) || ' ' }</FormHelperText>
                  </FormControl>
                  <FormControl margin="normal" fullWidth>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={ isSubmitting }
                    >
                      验证手机号
                    </Button>
                  </FormControl>
                </>
              )
              case 1:
              return (
                <>
                  <FormControl className={ classes.smsCodeInput } margin="normal">
                    <Box marginBottom={ 2 }>
                      <SMSTextField
                        values={ [{
                          name: 'smsCode0',
                          value: values.smsCode0,
                          touched: touched.smsCode0,
                          error: errors.smsCode0,
                        }, {
                          name: 'smsCode1',
                          value: values.smsCode1,
                          touched: touched.smsCode1,
                          error: errors.smsCode1,
                        }, {
                          name: 'smsCode2',
                          value: values.smsCode2,
                          touched: touched.smsCode2,
                          error: errors.smsCode2,
                        }, {
                          name: 'smsCode3',
                          value: values.smsCode3,
                          touched: touched.smsCode3,
                          error: errors.smsCode3,
                        }] }
                        autoFocus
                        variant="outlined"
                        onChange={ this.handleInputSMSCode }
                        onFocus={ this.handleInputFocus }
                        InputProps={ {
                          classes: { input: classes.smsCodeFontSize },
                        } }
                      >
                        <FormHelperText
                          className={ classes.textAlign }
                          error={ touched.smsCode && Boolean(errors.smsCode) }
                        >
                          { (touched.smsCode && errors.smsCode) || ' ' }
                        </FormHelperText>
                      </SMSTextField>
                    </Box>
                  </FormControl>
                  <FormControl margin="normal" fullWidth>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={ isSubmitting }
                    >
                      创建账号
                    </Button>
                  </FormControl>
                  <FormControl className={ classes.textAlign } margin="normal" fullWidth>
                    <Link onClick={ this.handleResendSMS }>没有收到短信</Link>
                  </FormControl>
                </>
              )
              case 2:
              return (
                <>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      type="text"
                      label="昵称"
                      name="nickName"
                      variant="outlined"
                      onChange={ this.handleInputUpdate }
                      value={ values.nickName }
                      error={ touched.nickName && Boolean(errors.nickName) }
                      helperText={ (touched.nickName && errors.nickName) || ' ' }
                    />
                  </FormControl>
                  <FormControl margin="normal" fullWidth>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      { nextStepButtonLabel }
                    </Button>
                  </FormControl>
                </>
              )
              case 3:
              return (
                <>
                  <Box marginTop={ 4 } marginBottom={ 4 }>
                    <Typography>欢迎使用EZpic，您的账号已经创建完成</Typography>
                  </Box>
                  <FormControl margin="normal" fullWidth>
                    <Button
                      onClick={ this.handleFinish }
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      查看账号
                    </Button>
                  </FormControl>
                </>
              )
              default:
              return (
                <Typography>页面错误，请联系管理员</Typography>
              )
            }
          })()
        }
        </Box>

        <Stepper className={ classes.stepper } activeStep={ registerStepIndex }>
          <Step completed={ 0 < registerStepIndex }>
            <StepLabel>创建账号</StepLabel>
          </Step>
          <Step completed={ 1 < registerStepIndex }>
            <StepLabel>验证手机号</StepLabel>
          </Step>
          <Step completed={ 2 < registerStepIndex }>
            <StepLabel>完善资料</StepLabel>
          </Step>
        </Stepper>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={ snackBarState }
          autoHideDuration={ 6000 }
          onClose={ this.handleSnackBarClose }
          message={ snackBarMessage }
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={ this.handleSnackBarClose }>
                <Close fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </>
    )
  }
}

export default withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    phone: '',
    password: '',
    agree: false,
    smsCode: '',
    smsCode0: '',
    smsCode1: '',
    smsCode2: '',
    smsCode3: '',
    nickName: '',
  }),
  validationSchema: (props: FormProps) => ([
    Yup.object().shape({
      phone: Yup.string().required('请输入手机号').matches(/^\d{11}$/, '手机号码不正确'),
      password: Yup.string()
        .required('请创建密码')
        .matches(/^(?=.*[a-z])/, '密码必须包含小写字母')
        .matches(/^(?=.*[A-Z])/, '密码必须包含大写字母')
        .matches(/^(?=.*[0-9])/, '密码必须包含数字')
        .min(8, '密码过短'),
      agree: Yup.boolean().oneOf([ true ], '请同意用户协议'),
    }),
    Yup.object().shape({
      smsCode0: Yup.string().required('请输入验证码').min(1, '超过最小值').max(1, '超过最大值').matches(/^[0-9]+$/, "请输入数字"),
      smsCode1: Yup.string().required('请输入验证码').min(1, '超过最小值').max(1, '超过最大值').matches(/^[0-9]+$/, "请输入数字"),
      smsCode2: Yup.string().required('请输入验证码').min(1, '超过最小值').max(1, '超过最大值').matches(/^[0-9]+$/, "请输入数字"),
      smsCode3: Yup.string().required('请输入验证码').min(1, '超过最小值').max(1, '超过最大值').matches(/^[0-9]+$/, "请输入数字"),
    }),
    Yup.object().shape({
      nickName: Yup.string().max(20, '超过最大值').matches(/^[\u4e00-\u9fa5_a-zA-Z0-9]+$/, '只允许字母,数字,汉字和下划线')
    })
  ][props.registerStepIndex] || Yup.object().shape({})
  ),
  handleSubmit: async (values, { props, setSubmitting, setErrors, setTouched }) => {
    if (props.registerStepIndex === 0) {
      const { errors } = await gqlClient.mutate({
        mutation: SEND_SMS,
        variables: { phone: values.phone, category: 'signUp' },
      })
      if (errors) {
        setErrors({ phone: errors[0].message })
      } else {
        setSubmitting(false)
        setTouched({})
        props.onNextPage()
      }
    }
    if (props.registerStepIndex === 1) {
      const smsCode = [
        values.smsCode0,
        values.smsCode1,
        values.smsCode2,
        values.smsCode3,
      ].join('')
      const { data, errors } = await gqlClient.mutate({
        mutation: CREATE_ACCOUNT,
        variables: { phone: values.phone, password: values.password, smsCode },
      })
      if (errors) {
        setErrors({ smsCode: errors[0].message })
      } else {
        localStorage.setItem('token', data.createAccount.token)
        localStorage.setItem('tokenDate', moment().format())
        localStorage.setItem('refreshToken', data.createAccount.refreshToken)
        setSubmitting(false)
        setTouched({})
        props.onNextPage()
      }
    }
    if (props.registerStepIndex === 2) {
      if (values.nickName) {
        const { errors } = await gqlClient.mutate({
          mutation: UPDATE_ACCOUNT,
          variables: { nickName: values.nickName },
        })
        if (errors) {
          setErrors({ nickName: errors[0].message })
        } else {
          setSubmitting(false)
          setTouched({})
          props.onNextPage()
        }
      } else {
        setSubmitting(false)
        setTouched({})
        props.onNextPage()
      }
    }
  },
})(withStyles(styles)(SignUpFormik))
