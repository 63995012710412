import {
  Container,
  Divider,
  Button, IconButton, Snackbar,
  Tabs, Tab, Box, Typography, createStyles,
  Theme, withStyles, WithStyles, Grid,
} from "@material-ui/core"
import { Close } from "@material-ui/icons"
import { FormikProps, withFormik } from "formik"
import { inject, observer } from "mobx-react"
import React from "react"
import createHistory from "../main/createHistory"
import PhotoDialog from "../components/photoDialog"
import AccountStore from "../stores/accountStore"
import { PhotoFile, Stores } from "../types"
import { dateToString } from "../unit"
import AccountInfoForm from "../components/accountInfoForm"
import AccountResetPasswordForm from "../components/accountResetPasswordForm"
import moment from "moment"
import PhotoFolderStore from "../stores/photoFolderStore"
import { gqlClient } from "../main/graphql"
import { PhotoPaper } from "../components/photoPaper"

interface States {
  snackBarMessage: string
  snackBarState: boolean
  tabIndex: number
  dialogState: boolean
  dialogList: PhotoFile[]
  dialogIndex: number
}

interface TabPanelProps {
  index: any,
  value: any,
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
}

interface FormProps extends SelectedStores, WithStyles<typeof styles> {}

interface FormValues {
  phone: string,
  nickName: string,
  password: string,
  smsCode: string,
  smsCode0: string,
  smsCode1: string,
  smsCode2: string,
  smsCode3: string,
}

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flex: 1,
    '& .MuiTextField-root': {
      margin: theme.spacing(1, 0),
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  section: {
    margin: theme.spacing(3, 2, 8, 2),
  },
  textAlign: {
    textAlign: 'center',
  },
})

const a11yProps = (index: any) => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`,
})

class TabPanel extends React.Component<TabPanelProps> {
  render() {
    const { children, value, index, maxWidth, ...other } = this.props
    return (
      <div
        style={ { flex: 1 } }
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        {...other}
      >
        {value === index && (
          <Container maxWidth={ maxWidth || "sm"}>
            <Box>{ children }</Box>
          </Container>
        )}
      </div>
    )
  }
}

interface SelectedStores {
  accountStore: AccountStore
  photoFolderStore: PhotoFolderStore
}

interface Props extends SelectedStores, FormikProps<FormValues>, WithStyles<typeof styles> {}

@inject((stores: Stores): SelectedStores => ({
  accountStore: stores.accountStore,
  photoFolderStore: stores.photoFolderStore,
}))
@observer
class AccountPage extends React.Component<Props> {

  state: States = {
    snackBarMessage: '',
    snackBarState: false,
    tabIndex: 0,
    dialogState: false,
    dialogList: [],
    dialogIndex: 0,
  }

  componentDidMount = () => {
    const { accountStore } = this.props
    accountStore.initPage()
  }

  handleSnackBarClose = () => {
    this.setState({ snackBarState: false })
  }

  handleLogout = async () => {
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('token')
    localStorage.removeItem('tokenDate')
    await gqlClient.resetStore()
    createHistory.push('/')
  }

  handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabIndex: newValue })
  }

  handlePhotoFolderDialog = (e: any, v: string) => {
    const { accountStore } = this.props
    const photoFileIndex = accountStore.photoFileCollectionList.findIndex((item) => {
      return item?.id === v
    })
    if (accountStore.photoFileCollectionList.length) {
      this.setState({
        dialogList: accountStore.photoFileCollectionList,
        dialogIndex: photoFileIndex,
        dialogState: true,
      })
    }
  }

  handleSeedlingPhotoFileDialog = (e: any, v: string) => {
    const { accountStore } = this.props
    const photoFileIndex = accountStore.seedlingPhotoFileCollectionList.findIndex((item) => {
      return item?.id === v
    })
    if (accountStore.seedlingPhotoFileCollectionList.length) {
      this.setState({
        dialogList: accountStore.seedlingPhotoFileCollectionList,
        dialogIndex: photoFileIndex,
        dialogState: true,
      })
    }
  }

  handleCloseProjectPhotoDialog = () => {
    this.setState({ dialogState: false, dialogList: [] })
  }

  handleTruningPhotoDialog = (nextValue: number) => {
    const { photoFolderStore } = this.props
    const { dialogIndex, dialogList } = this.state
    const photoFile = dialogList[dialogIndex + nextValue]
    if (photoFile) {
      photoFolderStore.setDialogPhotoFileListIndex(photoFile.id)
      this.setState({
        dialogIndex: dialogIndex + nextValue,
      })
    }
  }

  handleCollection = (e: any, id: string, state: boolean) => {
    const { dialogList } = this.state
    const { photoFolderStore } = this.props
    photoFolderStore.setPhotoFileCollection(id, state)
    this.setState({ dialogList: dialogList.map((i) => {
      if (i.id === id) {
        i.isCollection = state
        i.collectionAmount += (state ? 1 : -1)
      }
      return i
    }) })
  }

  handleCollectionSeedling = (e: any, id: string, state: boolean) => {
    const { dialogList } = this.state
    const { photoFolderStore } = this.props
    photoFolderStore.setSeedlingPhotoFileCollection(id, state)
    dialogList.forEach((item) => {
      if (item.id === id) {
        item.isCollection = state
        item.collectionAmount += state ? 1 : -1
      }
    })
    this.setState({ dialogList })
  }

  handleDownload = async (e: any, id: string) => {
    const { photoFolderStore } = this.props
    return await photoFolderStore.getFileUrl(id)
  }

  handleDownloadSeedling = async (e: any, id: string) => {
    const { photoFolderStore } = this.props
    return await photoFolderStore.getSeedlingFileUrl(id)
  }

  render() {
    const { snackBarMessage, snackBarState, tabIndex, dialogState, dialogList, dialogIndex } = this.state
    const { classes, accountStore } = this.props
    const { photoFileCollectionList, seedlingPhotoFileCollectionList } = accountStore
    return (
      <Box className={ classes.root } paddingTop={ 6 }>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={ tabIndex }
          onChange={ this.handleChangeTab }
          className={ classes.tabs }
          selectionFollowsFocus={ true }
        >
          <Tab label="账户" {...a11yProps(0)} />
          <Tab label="项目图片收藏" {...a11yProps(1)} />
          <Tab label="选苗图片收藏" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={ tabIndex } index={0}>
          <Box className={ classes.section }>
            <Typography variant="h6" gutterBottom>账号类型</Typography>
            <Divider className={ classes.divider } variant="middle" />
            {
              (() => {
                switch (accountStore.category) {
                  case 'normal':
                  return (
                    <>
                      {
                        moment().isBefore(accountStore.expiredDate) ?
                        <>
                          <Typography variant="body1" gutterBottom>付费用户：您具有下载图片权限</Typography>
                          <Typography variant="body1" gutterBottom>有效期至：{ dateToString(accountStore.expiredDate || new Date()) }</Typography>
                        </> :
                        <>
                          <Typography variant="body1" gutterBottom>普通账户：您可以修改账户类型来获取完整权限</Typography>
                        </>
                      }
                    </>
                  )
                  default:
                  return (
                    <Typography variant="body1" gutterBottom>用户类型空缺</Typography>
                  )
                }
              })()
            }
          </Box>
          <Box className={ classes.section }>
            <AccountInfoForm
              phone={ accountStore.phone }
              nickName={ accountStore.nickName }
            />
          </Box>
          <Box className={ classes.section }>
            <AccountResetPasswordForm phone={ accountStore.phone } />
          </Box>
          <Box className={ classes.section }>
            <Typography variant="h6" gutterBottom>退出账户</Typography>
            <Divider className={ classes.divider } variant="middle" />
            <Typography variant="body1" gutterBottom>感谢您使用我们的服务，有任何的改进意见欢迎联系我们</Typography>
            <Button variant="contained" color="secondary" onClick={ this.handleLogout }>登出</Button>
          </Box>
        </TabPanel>
        <TabPanel value={ tabIndex } maxWidth="lg" index={1}>
          <Grid container spacing={ 2 } justify={ seedlingPhotoFileCollectionList.length ? "flex-start" : "center"}>
          {
            photoFileCollectionList.length
            ? photoFileCollectionList.map((item, index) => (
                <Grid item key={ index } xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 } xl={ 2 }>
                  <PhotoPaper
                    value={ item }
                    onClick={ this.handlePhotoFolderDialog }
                  />
                </Grid>
              ))
            : <Box marginTop={ 6 } marginBottom={ 6 }>
                <Typography variant="h2" gutterBottom color="textSecondary">
                  还没有收藏项目图片
                </Typography>
              </Box>
          }
          </Grid>
          <PhotoDialog
            accountStore={ accountStore }
            onDownload={ this.handleDownload }
            onNext={ () => this.handleTruningPhotoDialog(1) }
            onPrev={ () => this.handleTruningPhotoDialog(-1) }
            onClose={ this.handleCloseProjectPhotoDialog }
            open={ dialogState }
            onCollection={ this.handleCollection }
            value={ {
              index: dialogIndex,
              list: dialogList,
            } }
          />
        </TabPanel>
        <TabPanel value={ tabIndex } maxWidth="lg" index={2}>
          <Grid container spacing={ 2 } justify={ seedlingPhotoFileCollectionList.length ? "flex-start" : "center"}>
          {
            seedlingPhotoFileCollectionList.length
            ? seedlingPhotoFileCollectionList.map((item, index) => (
                <Grid item key={ index } xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 } xl={ 2 }>
                  <PhotoPaper
                    value={ item }
                    onClick={ this.handleSeedlingPhotoFileDialog }
                  />
                </Grid>
              ))
            : <Box marginTop={ 6 } marginBottom={ 6 }>
                <Typography variant="h2" gutterBottom color="textSecondary">
                  没有收藏苗木照片
                </Typography>
              </Box>
          }
          </Grid>
          <PhotoDialog
            accountStore={ accountStore }
            onDownload={ this.handleDownloadSeedling }
            onNext={ () => this.handleTruningPhotoDialog(1) }
            onPrev={ () => this.handleTruningPhotoDialog(-1) }
            onClose={ this.handleCloseProjectPhotoDialog }
            open={ dialogState }
            onCollection={ this.handleCollectionSeedling }
            value={ {
              index: dialogIndex,
              list: dialogList,
            } }
          />
        </TabPanel>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={ snackBarState }
          autoHideDuration={ 6000 }
          onClose={ this.handleSnackBarClose }
          message={ snackBarMessage }
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={ this.handleSnackBarClose }>
                <Close fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Box>
    )
  }
}

export default withStyles(styles)(withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    phone: '',
    password: '',
    nickName: '',
    smsCode: '',
    smsCode0: '',
    smsCode1: '',
    smsCode2: '',
    smsCode3: '',
  }),
  handleSubmit: (values, { props }) => {}
})(AccountPage))

