import { Box, createStyles, TextField, Theme, WithStyles, withStyles } from "@material-ui/core"
import React from "react"

const styles = (theme: Theme) => createStyles({
  textAlign: {
    textAlign: 'center',
  },
})

interface SMSTextFieldValue {
  name: string,
  value: any,
  touched?: any,
  error?: any,
}

interface Props extends WithStyles<typeof styles> {
  values: SMSTextFieldValue[],
  InputProps?: any,
  variant?: string,
  autoFocus?: boolean,
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

class SMSTextField extends React.Component<Props> {

  smsCodeRefList: React.RefObject<HTMLInputElement>[] = []

  constructor(props: Props) {
    super(props)
    props.values.forEach(_ => {
      this.smsCodeRefList.push(React.createRef())
    })
  }

  handleInputSMSCode = (e: React.ChangeEvent<HTMLInputElement>, refIndex: number) => {
    const { onChange } = this.props
    const nextRef = this.smsCodeRefList[refIndex + 1]
    const currentRef= this.smsCodeRefList[refIndex]
    if (nextRef && nextRef.current) {
      nextRef.current.focus()
    } else if (currentRef && currentRef.current) {
      currentRef.current.blur()
    }
    if (onChange) {
      onChange(e)
    }
  }

  handleSetRef = (ref: React.RefObject<any>) => {
    this.smsCodeRefList.push(ref)
  }

  render() {
    const {
      // classes,
      values,
      InputProps,
      variant,
      autoFocus,
      onFocus,
      children,
    } = this.props
    const textFieldProps: any = {}
    if (variant) {
      textFieldProps.variant = variant
    }
    if (InputProps) {
      textFieldProps.InputProps = InputProps
    }
    return (
      <Box>
        {
          values.map((item, index) => (
            <TextField
              autoFocus={ index === 0 && autoFocus }
              key={ item.name }
              name={ item.name }
              inputRef={ this.smsCodeRefList[index] }
              value={ item.value }
              onChange={ (e: any) => this.handleInputSMSCode(e, index) }
              onFocus={ onFocus }
              autoComplete="one-time-code"
              type="text"
              error={ (item.touched && item.error) ? (item.touched && Boolean(item.error)) : false }
              { ...textFieldProps }
            />
          ))
        }
        { children }
      </Box>
    )
  }
}

export default withStyles(styles)(SMSTextField)
