import { makeObservable, observable, action, computed } from 'mobx'

export default class BasicUsageStore {

  constructor(){
    makeObservable(this)
  }

  @observable counter: number = 0

  @action increment = (): void => {
    this.counter++
    console.log('this: ', this)
  }

  @computed    //当数据变化时发生改变时， 自动触发
  get total() {
    console.log('total: ')
    return this.counter + 1
  }
}
