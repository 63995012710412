import React from 'react'
import { Fab, useScrollTrigger } from '@material-ui/core'
import { Route, RouteComponentProps } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { ArrowUpward } from '@material-ui/icons'

import { Stores } from './types'
import AccountStore from './stores/accountStore'
import Footer from './components/footer'
import NavTop from './components/navTop'
import HomePage from './pages/homePage'
import FindPage from './pages/findPage'
import RecruitPage from './pages/recruitPage'
import AccountPage from './pages/accountPage'
import ScrollTop from './components/scrollTop'
import PrivacyPolicyPage from './pages/privacyPolicyPage'
import ServiceTermsPage from './pages/serviceTermsPage'

interface MatchParams {
  [x: string]: string
}

interface SelectedStores {
  accountStore?: AccountStore
}

interface Props extends SelectedStores, RouteComponentProps<MatchParams> {}

const ElevationScroll = (props: { window?: () => Window, children: React.ReactElement[] }) => {
  const { children, window } = props
  const trigger = useScrollTrigger({ target: window ? window() : undefined })
  return (
    <React.Fragment>
      { React.Children.map(children, child => (
        React.cloneElement(child, {
          trigger,
        })
      )) }
    </React.Fragment>
  )
}

@inject((stores: Stores): SelectedStores => ({ accountStore: stores.accountStore }))
@observer
export default class App extends React.Component<Props> {
  render() {
    return (
      <ElevationScroll>
        <NavTop { ...this.props }>
          <Route exact path="/" component={ HomePage } />
          <Route path="/find" component={ FindPage } />
          <Route path="/recruit" component={ RecruitPage } />
          <Route path="/account" component={ AccountPage } />
          <Route path="/privacy-policy" component={ PrivacyPolicyPage } />
          <Route path="/service-terms" component={ ServiceTermsPage } />
        </NavTop>
        <Footer />
        <ScrollTop scrollInto="#back-to-top-anchor" >
          <Fab color="secondary">
            <ArrowUpward />
          </Fab>
        </ScrollTop>
      </ElevationScroll>
    )
  }
}
