import { createStyles, makeStyles, Theme, useScrollTrigger, Zoom } from "@material-ui/core"
import React from "react"

interface Props {
  window?: () => Window;
  children: React.ReactElement;
  scrollInto: string,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
)

export default function ScrollTop(props: Props) {
  const { children, window, scrollInto } = props
  const classes = useStyles()
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 170,
  })

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((e.target as HTMLDivElement).ownerDocument || document).querySelector(scrollInto)
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <>
      <Zoom in={ trigger } style={ { zIndex: 2999 } }>
        <div onClick={ handleClick } className={ classes.root }>
          { children }
        </div>
      </Zoom>
    </>
  )
}
