import { createStyles, Theme, Typography, WithStyles, withStyles } from "@material-ui/core"
import React from "react"
import InfiniteScroll from "react-infinite-scroll-component"

const styles = (theme: Theme) => createStyles({
  scrollTooltip: {
    margin: theme.spacing(6, 0),
    textAlign: 'center',
  },
})

interface Props extends WithStyles<typeof styles> {
  dataLength: number
  next: () => void
  hasMore: boolean
  empty: JSX.Element | string
}

class RecruitInfiniteScroll extends React.Component<Props> {
  render() {
    const { classes, children, dataLength, hasMore, empty, next } = this.props
    return (
      <InfiniteScroll
        style={ { width: '100%', overflow: 'hidden' } }
        dataLength={ dataLength }
        next={ next }
        hasMore={ hasMore }
        loader={
          <Typography
            className={ classes.scrollTooltip }
            variant="h5"
            gutterBottom
            color="textSecondary"
          >加载中...</Typography>
        }
        endMessage={
          <Typography
            className={ classes.scrollTooltip }
            variant="h5"
            gutterBottom
            color="textSecondary"
          >这里到底部了</Typography>
        }
      >
        { dataLength ? children : empty }
      </InfiniteScroll>
    )
  }
}

export default withStyles(styles)(RecruitInfiniteScroll)
