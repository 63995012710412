import {
  Avatar, Box, Paper, CardActionArea, createStyles, Divider,
  Theme, Typography, WithStyles, withStyles,
} from "@material-ui/core"
import React from "react"
import { LocationOn } from '@material-ui/icons'
import { dateToString } from "../unit"
import { Recruit } from "../types"

interface Props extends WithStyles<typeof styles> {
  value: Recruit | undefined,
  onClick: (e: any, v: string) => void,
}

const styles = (theme: Theme) => createStyles({
  avatarLarge: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    fontSize: theme.spacing(4),
  }
})

class RecruitPaper extends React.Component<Props> {

  render() {
    const { value, onClick, classes } = this.props
    if (!value) {
      return (<></>)
    }
    const { id, company, location, description, position, releaseDate } = value
    return (
      <Box p={ 1 }>
        <Paper elevation={ 0 }>
          <CardActionArea onClick={ (e) => onClick(e, id) }>
            <Box m={ 2 }>
              <Box display="flex" marginBottom={ 2 }>
                <Avatar className={ classes.avatarLarge }>{ company.split('')[0] }</Avatar>
                <Box paddingLeft={ 2 } display="flex" flexDirection="column" justifyContent="space-between">
                  <Typography variant="h6">{ company }</Typography>
                  <Typography variant="caption" color="textSecondary">
                    <LocationOn fontSize="small"/>{ location }
                  </Typography>
                </Box>
              </Box>
              <Typography variant="subtitle2">{ position }</Typography>
              <Typography variant="body2">{ description }</Typography>
              <Box marginTop={ 1 } marginBottom={ 1 }>
                <Divider />
              </Box>
              <Typography color="textSecondary" variant="caption">{ dateToString(releaseDate) }</Typography>
            </Box>
          </CardActionArea>
        </Paper>
      </Box>
    )
  }
}

export default withStyles(styles)(RecruitPaper)
