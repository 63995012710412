import React from "react"
import { v4 as uuidv4 } from 'uuid';
import {
  Fab, Card, Box, CardActionArea, Grid,
  Typography, Zoom, withStyles,
  createStyles, Theme, WithStyles,
} from "@material-ui/core"
import { ArrowBack, ArrowForward } from '@material-ui/icons/'
import { PhotoFolder } from "../types"

const styles = (theme: Theme) => createStyles({
  paper: {
    marginRight: theme.spacing(),
  },
  scrollLeft: {
    position: 'absolute',
    left: - theme.spacing(),
    top: '50%',
  },
  scrollRight: {
    position: 'absolute',
    right: - theme.spacing(),
    top: '50%',
  },
})

type MoveDirection = 'left' | 'right'

interface Props extends WithStyles<typeof styles> {
  value: PhotoFolder | undefined,
  onClick: (e: any, id: string, v: number) => void,
}

class ProjectLargePaper extends React.Component<Props> {
  state = {
    paperId: `id-${uuidv4()}`,
    activeScrollLeftButton: false,
    activeScrollRightButton: false,
  }

  componentDidMount() {
    this.screenChange.bind(this)
    window.addEventListener('resize', this.screenChange)
    this.screenChange()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.screenChange)
  }

  screenChange = () => {
    const anchor = document.querySelector(`#${this.state.paperId}`)
    if (!anchor) {
      return
    }
    if (anchor.clientWidth < anchor.scrollWidth) {
      this.setState({ activeScrollRightButton: true })
    }
  }

  handleScrollImage = (
    e: any,
    moveDirection: MoveDirection
  ) => {
    const anchor = document.querySelector(`#${this.state.paperId}`)
    if (!anchor) {
      return
    }
    const moveUnit = moveDirection === 'left' ? -1 : 1
    const move = anchor.scrollLeft + moveUnit * anchor.clientWidth * 0.7
    anchor.scrollTo({ behavior: 'smooth', left: move })
    if (move > 0) {
      this.setState({ activeScrollLeftButton: true })
    } else {
      this.setState({ activeScrollLeftButton: false })
    }
    if (anchor.scrollWidth <= move + anchor.clientWidth) {
      this.setState({ activeScrollRightButton: false })
    } else {
      this.setState({ activeScrollRightButton: true })
    }
  }

  render() {
    const { value, classes, onClick } = this.props
    if (!value) {
      return (<></>)
    }
    return (
      <>
        <Grid item style={ { width: '100%' } }>
          <Box p={ 2 }>
            <Typography align="left" variant="h5" gutterBottom>
              { value.name }
            </Typography>
            <Typography align="left" variant="subtitle1" gutterBottom>{ value.note }</Typography>
            <Box style={ { position: 'relative' } }>
              <Box id={ this.state.paperId } style={ { display: 'flex', flexWrap: 'nowrap', overflow: 'hidden' } }>
                {
                  value.photoFileList.map((photoItem, photoIndex) => (
                    <Card
                      key={ photoIndex }
                      onClick={ (e) => onClick(e, value.id, photoIndex) }
                      className={ classes.paper }
                      style={ {
                        flexGrow: 0,
                        flexShrink: 0,
                        height: 200,
                        width: 200 * photoItem.pixelWidth / photoItem.pixelHeight,
                      } }
                    >
                      <CardActionArea
                        id={ `${this.state.paperId}-${photoIndex}` }
                        style={ {
                          width: '100%',
                          height: '100%',
                          backgroundColor: '#dddddd',
                        } }
                      >
                        <img
                          width="100%"
                          loading="lazy"
                          alt="图片加载失败"
                          src={`https://${photoItem.previewUrl}?x-oss-process=image/format,jpg/interlace,1/resize,l_350`}
                        />
                      </CardActionArea>
                    </Card>
                  ))
                }
              </Box>
              <Zoom in={ this.state.activeScrollLeftButton }>
                <Fab
                  className={ classes.scrollLeft }
                  size="small"
                  onClick={ e => this.handleScrollImage(e, 'left') }
                  style={ { transform: 'translate(0,-50%)' } }
                >
                  <ArrowBack />
                </Fab>
              </Zoom>
              <Zoom in={ this.state.activeScrollRightButton }>
                <Fab
                  className={ classes.scrollRight }
                  size="small"
                  onClick={ e => this.handleScrollImage(e, 'right') }
                  style={ { transform: 'translate(0,-50%)' } }
                >
                  <ArrowForward />
                </Fab>
              </Zoom>
            </Box>
          </Box>
        </Grid>
      </>
    )
  }
}

export default withStyles(styles)(ProjectLargePaper)
