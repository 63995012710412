import { Box, Button, createStyles, Divider, IconButton, Snackbar, TextField, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { Form, FormikProps, withFormik } from "formik";
import React from "react";
import * as Yup from 'yup'
import { gqlClient } from "../main/graphql";
import { Close } from "@material-ui/icons";
import { UPDATE_ACCOUNT } from "../service/accountService";

const styles = (theme: Theme) => createStyles({
  divider: {
    margin: theme.spacing(2, 0),
  },
  textAlign: {
    textAlign: 'center',
  },
  smsCodeFontSize: {
    fontSize: theme.spacing(5),
    textAlign: 'center',
  },
  smsCodeInput: {
    marginTop: theme.spacing(2),
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: theme.spacing(8),
    },
  },
})

interface FormProps {
  phone: string,
  nickName: string,
}

interface FormValues {
  phone: string,
  nickName: string,
}

interface States {}

interface Props extends WithStyles<typeof styles>, FormikProps<FormValues>, FormProps {}

class AccountInfoForm extends React.Component<Props, States> {

  handleSnackBarClose = () => {
    const { setStatus } = this.props
    setStatus({ showSnackBar: false })
  }

  render () {
    const {
      classes, values, isSubmitting, status,
      handleChange, handleSubmit,
    } = this.props
    return (
      <>
        <Box component={ Form }>
          <Typography variant="h6" gutterBottom>用户信息</Typography>
          <Divider className={ classes.divider } variant="middle" />
          <Typography variant="body1" gutterBottom>您可以修改有关账户的信息</Typography>
          <TextField
            name="phone"
            disabled={ true }
            label="手机号"
            variant="filled"
            autoComplete="phone"
            value={ values.phone }
            fullWidth={ true }
          />
          <TextField
            name="nickName"
            label="昵称"
            variant="filled"
            autoComplete="nickName"
            value={ values.nickName }
            onChange={ handleChange }
            helperText={ ' ' }
            fullWidth={ true }
          />
          <Button
            onClick={ () => handleSubmit() }
            variant="contained"
            color="primary"
            disabled={ isSubmitting }
          >提交信息</Button>
        </Box>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={ status.showSnackBar }
          autoHideDuration={ 6000 }
          onClose={ this.handleSnackBarClose }
          message={ status.snackBarMessage }
            action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={ this.handleSnackBarClose }>
                <Close fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </>
    )
  }
}

export default withFormik<FormProps, FormValues>({
  enableReinitialize: true,
  mapPropsToStatus: props => {
    return {
      showSnackBar: false,
      snackBarMessage: '',
    }
  },
  mapPropsToValues: props => {
    return {
      ...props,
      phone: props.phone,
      nickName: props.nickName,
    }
  },
  validationSchema: (props: FormProps) => (
    Yup.object().shape({
      nickName: Yup.string().max(20, '超过最大值').matches(/^[\u4e00-\u9fa5_a-zA-Z0-9]+$/, '只允许字母,数字,汉字和下划线'),
    })
  ),
  handleSubmit: async (values, { props, setStatus }) => {
    const { nickName } = values
    const { errors } = await gqlClient.mutate({
      mutation: UPDATE_ACCOUNT,
      variables: { nickName },
    })
    if (!errors) {
      setStatus({ showSnackBar: true, snackBarMessage: '昵称已修改' })
    }
  }
})(withStyles(styles)(AccountInfoForm))
