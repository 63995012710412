import { Link, Divider, Grid, IconButton, DialogTitle, Box, createStyles, Dialog, Theme, withStyles, WithStyles, withWidth, DialogContent, Typography } from "@material-ui/core"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"
import { Close } from "@material-ui/icons"
import { inject, observer } from "mobx-react"
import React from "react"
import AccountStore from "../stores/accountStore"
import { Recruit, Stores } from "../types"
import { dateToString } from "../unit"
import { ReportDialog } from "./ReportDialog"

const styles = (theme: Theme) => createStyles({
  typography: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
})

interface SelectedStores {
  accountStore: AccountStore
}

interface Props extends SelectedStores, WithStyles<typeof styles> {
  value: { list: Recruit[], index: number },
  onClose: () => void,
  open: boolean,
  width?: Breakpoint,
}

interface States {
  dialogState: boolean
}

@inject((stores: Stores): SelectedStores => ({
  accountStore: stores.accountStore,
}))
@observer
class RecruitDialog extends React.Component<Props, States> {

  state = {
    dialogState: false
  }

  handleSubmit = (context: string) => {
    const { accountStore } = this.props
    const { value } = this.props
    const { list, index } = value
    const recruit = list[index]
    accountStore.report(`${context}, ${JSON.stringify(recruit)}`)
    return true
  }

  handleReport =() => {
    this.setState({ dialogState: true })
  }

  handleClose = () => {
    this.setState({ dialogState: false })
  }

  render() {
    const { dialogState } = this.state
    const { onClose, open, width, value, classes } = this.props
    const { list, index } = value
    const recruit = list[index]
    if (!recruit) {
      return (
        <></>
      )
    }
    return (
      <>
        <Dialog
          onClose={ onClose }
          open={ open }
          fullScreen={ width === 'xs' }
          maxWidth="md"
          fullWidth={ true }
        >
          <DialogTitle>
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={ onClose }>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box paddingBottom={ 2 } display="flex" flexDirection="column" textAlign="center">
              <Typography variant="h4">{ recruit.company }</Typography>
              <Typography variant="subtitle1">{ recruit.location }</Typography>
            </Box>
            <Divider />
            <Grid container>
              <Grid item xs={ 12 } sm={ 7 } md={ 8 }>
                <Box m={ 2 }>
                  <Typography className={ classes.typography } variant="body1" component="div">
                    { recruit.description }
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={ 12 } sm={ 5 } md={ 4 }>
                <Box m={ 2 } whiteSpace="normal">
                  <Typography color="textSecondary" variant="subtitle1">发布日期</Typography>
                  <Typography
                    className={ classes.typography }
                    paragraph
                    variant="body1"
                  >{ dateToString(recruit.releaseDate) }</Typography>

                  <Typography color="textSecondary" variant="subtitle1">就职地点</Typography>
                  <Typography
                    className={ classes.typography }
                    paragraph
                    variant="body1"
                  >{ recruit.location }</Typography>

                  <Typography color="textSecondary" variant="subtitle1">联系方式</Typography>
                  <Typography
                    className={ classes.typography }
                    paragraph
                    variant="body1"
                  >{ recruit.contact }</Typography>

                  <Typography color="textSecondary" variant="subtitle1">邮箱地址</Typography>
                  <Typography
                    className={ classes.typography }
                    paragraph
                    variant="body1"
                  >{ recruit.email }</Typography>

                  <Link onClick={ this.handleReport } component="button" color="textSecondary" variant="overline">
                    反馈
                  </Link>

                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <ReportDialog dialogState={ dialogState } dialogClose={ this.handleClose } submit={ this.handleSubmit }/>
      </>
    )
  }
}

export default withStyles(styles)(withWidth()(RecruitDialog))
