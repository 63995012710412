import { Button, Menu, MenuItem, MenuItemProps } from "@material-ui/core"
import { ArrowDropDown } from "@material-ui/icons"
import React from "react"

export interface Option {
  key: string,
  text: string,
  value: string,
}

interface Props {
  data: Array<Option>,
  value: string,
  onChange: (e: any, value: Option) => void,
}

interface States {
  actionElement: null | HTMLElement,
}

export default class SelectMenu extends React.Component<Props> {

  state: States = {
    actionElement: null,
  }

  handleOpenOption = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      actionElement: e.currentTarget,
    })
  }

  handleCloseOption = (e: any, value: string | undefined = undefined) => {
    const { data = [] } = this.props
    const op = data.find(i => i.key === value)
    if (op) {
      this.props.onChange(e, op)
    }
    this.setState({
      actionElement: null,
    })
  }

  render() {
    const { actionElement } = this.state
    const { children, value, data } = this.props
    const childrenWithProps = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child as React.ReactElement<MenuItemProps>, {
          onClick: this.handleCloseOption,
        })
      }
    })
    return (
      <>
        <Button
          onClick={ e => this.handleOpenOption(e) }
          endIcon={ <ArrowDropDown /> }
          size="large"
        >
          { data.find((i) => i.value === value)?.text }
        </Button>
        <Menu
          anchorEl={ actionElement }
          keepMounted
          open={ Boolean(actionElement) }
          onClose={ this.handleCloseOption }
          PaperProps={{
            style: {
              maxHeight: 48 * 6,
            },
          }}
        >
          {
            data ?
            data.map(item => (
              <MenuItem key={ item.key } onClick={ _=> this.handleCloseOption(_, item.key) }>{ item.text}</MenuItem>
            )) :
            childrenWithProps
          }
        </Menu>
      </>
    )
  }
}