import { makeObservable, observable, action, runInAction, computed, toJS } from "mobx"
import { gqlClient } from "../main/graphql"
import { GET_ACCOUNT, REPORT_MESSAGE } from "../service/accountService"
import { PhotoFolder, Stores } from "../types"

export default class AccountStore {
  stores: Stores
  constructor(stores: Stores) {
    this.stores = stores
    makeObservable(this)
  }

  @observable nickName: string = ''
  @observable phone: string = ''
  @observable category: string = ''
  @observable expiredDate: Date | null = null
  @observable photoFolderCollectionIdList: string[] = []

  @action('初始化页面数据')
  initPage = async () => {
    const { data } = await gqlClient.query({
      query: GET_ACCOUNT,
      fetchPolicy: 'no-cache',
    })
    const {
      nickName,
      phone,
      category,
      expiredDate,
      photoFileCollectionList,
      seedlingPhotoFileCollectionList,
    } = data.getAccount
    this.stores.photoFolderStore.addPhotoFolderMap(photoFileCollectionList)
    this.stores.photoFolderStore.addSeedlingPhotoFileMap(seedlingPhotoFileCollectionList)
    runInAction(() => {
      this.nickName = nickName
      this.phone = phone
      this.category = category
      this.expiredDate = expiredDate
      this.photoFolderCollectionIdList = photoFileCollectionList.map((item: PhotoFolder) => item.id)
    })
    return { nickName, phone }
  }

  @computed get photoFileCollectionList() {
    const folderList = this.photoFolderCollectionIdList.map((i) => {
      return toJS(this.stores.photoFolderStore.photoFolderMap.get(i))
    })
    const fileList = folderList.map((i) => {
      return i ? i.photoFileList.filter((item) => item.isCollection).map((item) => {
        item.name = i.name
        return item
      }) : []
    })
    return fileList.length ? fileList.reduce((a, b) => a.concat(b)) : []
  }

  @computed get seedlingPhotoFileCollectionList() {
    return Array.from(this.stores.photoFolderStore.seedlingPhotoFileMap.values()).filter((item) => {
      return item.isCollection
    }).map((item) => {
      return toJS(item)
    })
  }

  @action('发送反馈')
  report = async (context: string) => {
    await gqlClient.mutate({
      mutation: REPORT_MESSAGE,
      variables: { context },
    })
  }
}
