import { gql } from '@apollo/client'

export const photoFolderBase = gql`
  fragment PhotoFolderBase on PhotoFolder {
    id,
    name,
    downloadAmount,
    collectionAmount,
    isCollection,
    collectionAmount,
    createDate,
  }
`

export const photoFileBase = gql`
  fragment PhotoFileBase on PhotoFile {
    id,
    name,
    size,
    pixelWidth,
    pixelHeight,
    previewUrl,
    createDate,
    downloadAmount,
    collectionAmount,
    viewAmount,
    isCollection,
  }
`

export const seedlingPhotoFileBase = gql`
  fragment SeedlingPhotoFileBase on SeedlingPhotoFile {
    id,
    name,
    size,
    pixelWidth,
    pixelHeight,
    previewUrl,
    createDate,
    downloadAmount,
    collectionAmount,
    viewAmount,
    isCollection,
  }
`

export const GET_PHOTO_FOLDER_LIST = gql`
  ${photoFolderBase}
  ${photoFileBase}
  query(
    $searchString: String,
    $tagList: [String!],
    $sp: Int!,
    $ps: Int!,
    $fileSp: Int!,
    $filePs: Int!,
  ) {
    getPhotoFolderList(
      searchString: $searchString,
      tagList: $tagList,
      pageQuery: {
        sp: $sp,
        ps: $ps,
      },
    ) {
      count,
      photoFolderList {
        ...PhotoFolderBase,
        tagList {
          name,
        },
        photoFileList (
          pageQuery: {
            sp: $fileSp,
            ps: $filePs,
          },
        ) {
          ...PhotoFileBase,
          tagList {
            name,
          },
        },
      },
    }
  }
`

export const GET_SEEDLING_PHOTO_FILE_LIST = gql`
  ${seedlingPhotoFileBase}
  query(
    $searchString: String,
    $tagList: [String!],
    $sp: Int!,
    $ps: Int!,
  ) {
    getSeedlingPhotoFileList (
      searchString: $searchString,
      tagList: $tagList,
      pageQuery: {
        sp: $sp,
        ps: $ps,
      },
    ) {
      count,
      seedlingPhotoFileList {
        ...SeedlingPhotoFileBase,
        tagList {
          name,
        },
      }
    }
  }
`

export const GET_PHOTO_FILE = gql`
  query(
    $id: String!,
  ) {
    getPhotoFile(
      id: $id,
    ) {
      id,
    },
  }
`

export const GET_PHOTO_FILE_URI = gql`
  query(
    $id: String!,
  ) {
    getPhotoFile(
      id: $id,
    ) {
      fileUrl,
    },
  }
`

export const GET_SEEDLING_PHOTO_FILE_URI = gql`
  query(
    $id: String!,
  ) {
    getSeedlingPhotoFile(
      id: $id,
    ) {
      fileUrl,
    },
  }
`

export const UPDATE_PHOTO_FOLDER_IS_COLLECTION = gql`
  mutation($id: String!, $state: Boolean!) {
    updatePhotoFolderIsCollection(
      id: $id,
      state: $state,
    )
  }
`

export const UPDATE_PHOTO_FILE_IS_COLLECTION = gql`
  mutation($id: String!, $state: Boolean!) {
    updatePhotoFileIsCollection(
      id: $id,
      state: $state,
    )
  }
`

export const UPDATE_SEEDLING_PHOTO_FILE_IS_COLLECTION = gql`
  mutation($id: String!, $state: Boolean!) {
    updateSeedlingPhotoFileIsCollection(
      id: $id,
      state: $state,
    )
  }
`
