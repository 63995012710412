import {
  Chip, Zoom, Fab, withWidth, Link,
  Box, Dialog, DialogContent,
  DialogTitle, IconButton, Typography, Grid,
  createStyles, Theme, withStyles, WithStyles, Button, Snackbar,
} from "@material-ui/core"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"
import { Close, ArrowBack, ArrowForward, GetApp, Favorite } from "@material-ui/icons"
import { GraphQLError } from "graphql"
import { inject, observer } from "mobx-react"
import React from "react"
import AccountStore from "../stores/accountStore"
import { PhotoFile, Stores } from "../types"
import { dateToString } from "../unit"
import { ReportDialog } from "./ReportDialog"

const styles = (theme: Theme) => createStyles({
  scrollLeft: {
    position: 'absolute',
    left: - theme.spacing(),
    top: '50%',
  },
  scrollRight: {
    position: 'absolute',
    right: - theme.spacing(),
    top: '50%',
  },
  image: {
    maxHeight: 568,
    maxWidth: "100%",
  },
  download: {
    margin: theme.spacing(10, 0, 16, 0),
  },
})

interface SelectedStores {
  accountStore: AccountStore
}

interface Props extends SelectedStores, WithStyles<typeof styles> {
  value: { list: PhotoFile[], index: number },
  onDownload: (e: any, id: string) => Promise<readonly GraphQLError[] | undefined>,
  onClose: () => void,
  onNext: () => void,
  onPrev: () => void,
  onCollection: (e: any, id: string, state: boolean) => void,
  open: boolean,
  width?: Breakpoint,
}

interface States {
  dialogState: boolean
  snackBarState: boolean
  snackBarMessage: string
}

@inject((stores: Stores): SelectedStores => ({
  accountStore: stores.accountStore,
}))
@observer
class PhotoDialog extends React.Component<Props, States> {

  state = {
    dialogState: false,
    snackBarState: false,
    snackBarMessage: '',
  }

  handleSubmit = (context: string) => {
    const { accountStore, value } = this.props
    const detail = value.list[value.index]
    accountStore.report(`${context}, ${JSON.stringify(detail)}`)
    return true
  }

  handleReport =() => {
    this.setState({ dialogState: true })
  }

  handleClose = () => {
    this.setState({ dialogState: false })
  }

  handleDownload = async (e: any, id: string) => {
    const { onDownload } = this.props
    const errors = await onDownload(e, id)
    if (errors && errors[0]) {
      this.setState({
        snackBarState: true, snackBarMessage: errors[0].message
      })
    }
  }

  handleSnackBarClose = () => {
    this.setState({ snackBarState: false })
  }

  render() {
    const { dialogState, snackBarState, snackBarMessage } = this.state
    const {
      classes,
      onClose,
      onNext,
      onPrev,
      onCollection,
      open,
      width,
      value,
    } = this.props
    const detail = value.list[value.index]
    if (!detail) {
      return (<></>)
    }
    return (
      <>
        <Dialog
          onClose={ onClose }
          open={ open }
          fullScreen={ width === 'xs' }
          fullWidth={ true }
          maxWidth="lg"
        >
          <DialogTitle>
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={ onClose }>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={ 2 } justify="center">
              <Grid item sm={ 8 } xs={ 12 }>
                <Box display="flex" flexDirection="column" boxSizing="inherit" height="100%">
                  <Box display="flex" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                      <Typography variant="h5">{ detail.name }</Typography>
                    </Box>
                    <Box>
                      <IconButton
                        onClick={ (e) => onCollection(e, detail.id, !detail.isCollection)}
                        color={ detail.isCollection ? "secondary" : "default"}
                      >
                        <Favorite />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    p={ 1 }
                    width={ 1 }
                    position="relative"
                    alignItems="center"
                    boxSizing="inherit"
                    justifyContent="center"
                    flexGrow={ 1 }
                  >
                    <img className={ classes.image } alt="" src={ `https://${detail.previewUrl}?x-oss-process=image/resize,l_1000` } />
                    {
                      value.index > 0 ?
                      <Zoom in={ true }>
                        <Fab
                          className={ classes.scrollLeft }
                          onClick={ onPrev }
                          size="small"
                          style={ { transform: 'translate(0,-50%)' } }
                        >
                          <ArrowBack />
                        </Fab>
                      </Zoom> : null
                    }
                    {
                      value.index + 1 < value.list.length ?
                      <Zoom in={ true }>
                        <Fab
                          className={ classes.scrollRight }
                          onClick={ onNext }
                          size="small"
                          style={ { transform: 'translate(0,-50%)' } }
                        >
                          <ArrowForward />
                        </Fab>
                      </Zoom> : null
                    }
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={ 4 } xs={ 12 } container>
                <Box m={ 2 } paddingLeft={ 1 } width="100%">
                  <Box paddingBottom={ 2 }>
                    <Typography variant="h6">详细信息</Typography>
                    <Typography variant="subtitle1">创建时间: { dateToString(detail.createDate) }</Typography>
                  </Box>
                  <Box paddingBottom={ 2 }>
                    <Typography variant="subtitle1">查看次数: { detail.viewAmount }</Typography>
                    <Typography variant="subtitle1">下载次数: { detail.downloadAmount }</Typography>
                    <Typography variant="subtitle1">收藏次数: { detail.collectionAmount }</Typography>
                  </Box>
                  <Box paddingBottom={ 2 }>
                    <Typography variant="subtitle1">标签</Typography>
                    <Box display="flex" flexWrap="wrap">
                      {
                        detail.tagList?.map((item, index) => (
                          <Box key={ index } m={ 0.5 }><Chip label={ item.name } variant="outlined" /></Box>
                        ))
                      }
                    </Box>
                  </Box>
                  <Link onClick={ this.handleReport } component="button" color="textSecondary" variant="overline">
                    内容有错？
                  </Link>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={ classes.download }
                    startIcon={<GetApp />}
                    fullWidth={ true }
                    onClick={ e => this.handleDownload(e, detail.id) }
                  >
                    下载
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <ReportDialog dialogState={ dialogState } dialogClose={ this.handleClose } submit={ this.handleSubmit }/>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={ snackBarState }
          autoHideDuration={ 6000 }
          onClose={ this.handleSnackBarClose }
          message={ snackBarMessage }
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={ this.handleSnackBarClose }>
                <Close fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </>
    )
  }
}

export default withStyles(styles)(withWidth()(PhotoDialog))
