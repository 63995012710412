import React from 'react'
import ReactDOM from 'react-dom'
import { Router, Route, Switch } from 'react-router'
import { Provider } from 'mobx-react'
import 'fontsource-roboto'

import './index.css'
import './App.css'
import reportWebVitals from './reportWebVitals'
import createMobxStores from './main/createMobxStores'
import createHistory from './main/createHistory'
import App from "./App"
import SignPage from "./pages/signPage"
import { createMuiTheme, ThemeProvider } from '@material-ui/core'

const rootStores = createMobxStores()
const history = createHistory

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2e9244',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider className="App" { ...rootStores }>
      <ThemeProvider theme={ theme }>
        <div className="App-header">
          <Router history={ history }>
            <Switch>
              <Route path="/sign" component={ SignPage } />
              <Route path="/" component={ App } />
            </Switch>
          </Router>
        </div>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
