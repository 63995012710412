import { Box, Container, Button, FormControl, Link, TextField, withStyles, Theme, createStyles, WithStyles } from "@material-ui/core"
import { Form, FormikProps, withFormik } from "formik"
import * as Yup from 'yup'
import React from "react"
import moment from 'moment'
import { gqlClient } from "../main/graphql"
import { LOGIN } from "../service/accountService"

const styles = (theme: Theme) => createStyles({
  textAlign: {
    textAlign: 'center',
  },
})

interface FormProps {
  onSignReset?: (e: any) => void,
  onJump: () => void,
}

interface FormValues {
  phone: string,
  password: string,
}

interface Props extends FormProps, WithStyles<typeof styles>, FormikProps<FormValues> {}

class SignInForm extends React.Component<Props> {

  render() {
    const {
      classes,
      children, onSignReset,
      values, handleChange,
      touched, errors,
      isSubmitting,
    } = this.props
    return (
      <Container maxWidth="xs">
        <Box
          component={ Form }
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          { children }
          <FormControl margin="normal" fullWidth>
            <TextField
              name="phone"
              label="手机号"
              type="text"
              autoComplete="phone"
              variant="filled"
              value={ values.phone }
              onChange={ handleChange }
              error={ touched.phone && Boolean(errors.phone) }
              helperText={ (touched.phone && errors.phone) || ' ' }
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <TextField
              name="password"
              label="密码"
              type="password"
              autoComplete="password"
              variant="filled"
              value={ values.password }
              onChange={ handleChange }
              error={ touched.password && Boolean(errors.password) }
              helperText={ (touched.password && errors.password) || ' ' }
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={ isSubmitting }
            >
              登陆
            </Button>
          </FormControl>
          <FormControl className={ classes.textAlign } margin="normal" fullWidth>
            <Link onClick={ onSignReset }>忘记密码？</Link>
          </FormControl>
        </Box>
      </Container>
    )
  }
}

export default withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    phone: '',
    password: '',
  }),
  validationSchema: Yup.object().shape({
    phone: Yup.string().required('请输入手机号').matches(/^\d{11}$/, '手机号码不正确'),
    password: Yup.string().required('请输入密码')
  }),
  handleSubmit: async (values, { props, setSubmitting, setErrors, setTouched }) => {
    const { data, errors } = await gqlClient.mutate({
      mutation: LOGIN,
      variables: { phone: values.phone, password: values.password },
    })
    if (errors) {
      setErrors({ password: errors[0].message })
    } else {
      localStorage.setItem('token', data.login.token)
      localStorage.setItem('tokenDate', moment().format())
      localStorage.setItem('refreshToken', data.login.refreshToken)
      setSubmitting(false)
      setTouched({})
      props.onJump()
    }
  }
})(withStyles(styles)(SignInForm))
