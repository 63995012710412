import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import moment from 'moment'
import { onError } from '@apollo/client/link/error'
import { ACCOUNTREFRESHTOKEN } from '../service/accountService'
import { GraphQLError } from 'graphql/error/GraphQLError'
import createHistory from './createHistory'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_ENV_URI,
})

const authLink = setContext(async (_, { headers }) => {
  let token = localStorage.getItem('token')
  let tokenDate = localStorage.getItem('tokenDate')
  let refreshToken = localStorage.getItem('refreshToken')
  // token过期了刷新一下
  if (tokenDate && moment().subtract(5, 'days').isAfter(tokenDate)) {
    const client = new ApolloClient({
      link: setContext(() => ({
        headers: {
          ...headers,
          authorization: `Bearer ${refreshToken}`,
        },
      })).concat(httpLink),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          errorPolicy: 'all',
        },
      },
    })
    const { data, errors } = await client.query({
      query: ACCOUNTREFRESHTOKEN,
    })
    if (errors) {
      alert('请联系管理员')
      return
    }
    const dateString = moment().format()
    localStorage.setItem('token', data.accountRefreshToken)
    localStorage.setItem('tokenDate', dateString)
    token = data.accountRefreshToken
    tokenDate = dateString
  }
  return token
  ? {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`
    },
  }
  : { headers }
})

const errorLink = onError(({ graphQLErrors }) => {
  let jump = false
  graphQLErrors?.forEach((err: GraphQLError) => {
    if (err.extensions?.exception.status === 401) {
      jump = true
    }
  })
  if (jump) {
    const url = createHistory.location.pathname + createHistory.location.search
    createHistory.push(`/sign?url=${encodeURIComponent(url)}`)
  }
})

export const gqlClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authLink,
    httpLink,
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
})
