import { action, computed, makeObservable, observable, runInAction, toJS } from "mobx";
import { Recruit } from "../types";
import { gqlClient } from "../main/graphql";
import { GET_RECRUIT_LIST } from "../service/recruitService";

export class RecruitStore {
  constructor(){
    makeObservable(this)
  }

  @observable recruitMap = new Map<string, Recruit>()

  @observable findRecruitIdList: string[] = []
  @observable findRecruitCount = 0

  @computed get findRecruit() {
    return (this.findRecruitIdList.map((item) => {
      return toJS(this.recruitMap.get(item))
    })).filter((i): i is Recruit => !!i)
  }

  @action('初始化招聘列表')
  initFindRecruitIdList = async (tagList: string[], searchString?: string) => {
    const { data } = await gqlClient.query({
      query: GET_RECRUIT_LIST,
      variables: {
        tagList,
        searchString,
        ps: 36,
        sp: 0,
      },
    })
    const { getRecuritList } = data
    runInAction(() => {
      this.findRecruitIdList = getRecuritList.recruitList.map((item: Recruit) => {
        this.recruitMap.set(item.id, item)
        return item.id
      })
    })
  }

  @action('加载招聘列表')
  nextFindRecruitIdList = async (tagList: string[], searchString?: string) => {
    const { data } = await gqlClient.query({
      query: GET_RECRUIT_LIST,
      variables: {
        tagList,
        searchString,
        ps: 36,
        sp: this.findRecruitIdList.length,
      },
    })
    const { getRecuritList } = data
    runInAction(() => {
      const idList = getRecuritList.recruitList.map((item: Recruit) => {
        this.recruitMap.set(item.id, item)
        return item.id
      })
      this.findRecruitIdList.push(...idList)
    })
  }
}
