import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar, TextField } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";

interface Props {
  dialogState: boolean
  dialogClose: () => void
  submit: (context: string) => boolean
}

interface States {
  snackBarState: boolean,
  snackBarMessage: string,
  reportMessage: string,
}

export class ReportDialog extends React.Component<Props, States> {
  state = {
    snackBarState: false,
    snackBarMessage: '',
    reportMessage: '',
  }

  handleChange = (e: any) => {
    this.setState({ reportMessage: e.target.value })
  }

  handleSubmit = () => {
    const { submit, dialogClose } = this.props
    const { reportMessage } = this.state
    const state = submit(reportMessage)
    if (state === false) {
      return
    }
    this.setState({ snackBarState: true, snackBarMessage: '感谢您的反馈' })
    dialogClose()
  }

  handleSnackBarClose = () => {
    this.setState({ snackBarState: false })
  }

  render() {
    const { dialogState, dialogClose } = this.props
    const { snackBarState, snackBarMessage, reportMessage } = this.state
    return (
      <>
        <Dialog open={ dialogState } onClose={ dialogClose } maxWidth="sm" fullWidth={ true }>
          <DialogTitle>内容反馈</DialogTitle>
          <DialogContent>
            <DialogContentText>报告信息内容问题，或者改进建议</DialogContentText>
            <TextField
              value={ reportMessage }
              fullWidth={ true }
              variant="filled"
              placeholder="输入内容"
              onChange={ this.handleChange }
              multiline
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={ dialogClose } color="primary">取消</Button>
            <Button onClick={ this.handleSubmit } color="primary" autoFocus>提交</Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={ snackBarState }
          autoHideDuration={ 6000 }
          onClose={ this.handleSnackBarClose }
          message={ snackBarMessage }
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={ this.handleSnackBarClose }>
                <Close fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </>
    )
  }
}
