import { makeObservable, observable, action, computed, runInAction, toJS } from "mobx"
import moment from 'moment'
import { gqlClient } from "../main/graphql"
import {
  GET_PHOTO_FILE,
  GET_PHOTO_FILE_URI,
  GET_PHOTO_FOLDER_LIST,
  GET_SEEDLING_PHOTO_FILE_LIST,
  GET_SEEDLING_PHOTO_FILE_URI,
  UPDATE_PHOTO_FILE_IS_COLLECTION,
  UPDATE_PHOTO_FOLDER_IS_COLLECTION,
  UPDATE_SEEDLING_PHOTO_FILE_IS_COLLECTION,
} from "../service/photoFolderService"
import { PhotoFolder, SeedlingPhotoFile } from "../types"

export default class PhotoFolderStore {
  constructor() {
    makeObservable(this)
  }

  @observable photoFolderMap = new Map<string, PhotoFolder>()
  @observable seedlingPhotoFileMap = new Map<string, SeedlingPhotoFile>()

  @observable projectPhotoFolderIdList: string[] = []
  @observable projectPhotoFolderCount = 0

  @observable treePhotoFileIdList: string[] = []
  @observable treePhotoFileCount = 0

  @observable hotCityIdList: string[] = []
  @observable freshIdList: string[] = []
  @observable recommendPhotoFileIdList: string[] = []

  @computed get hotCityPhotoFolder() {
    return this.hotCityIdList.map((item) => {
      return toJS(this.photoFolderMap.get(item))
    })
  }

  @computed get freshPhotoFolder() {
    return this.freshIdList.map((item) => {
      return toJS(this.photoFolderMap.get(item))
    })
  }

  @computed get recommendPhotoFile() {
    return this.recommendPhotoFileIdList.map((item) => {
      return toJS(this.seedlingPhotoFileMap.get(item))
    })
  }

  @computed get projectPhotoFolder() {
    return this.projectPhotoFolderIdList.map((item) => {
      return toJS(this.photoFolderMap.get(item))
    })
  }

  @computed get treePhotoFile() {
    return this.treePhotoFileIdList.map((item) => {
      return toJS(this.seedlingPhotoFileMap.get(item))
    })
  }

  @action('初始化热门城市列表')
  initHotCityIdList = async (tagList: string[], searchString?: string) => {
    const { data } = await gqlClient.query({
      query: GET_PHOTO_FOLDER_LIST,
      variables: {
        tagList,
        searchString,
        sp: 0,
        ps: 6,
        fileSp: 0,
        filePs: -1,
      },
    })
    const { getPhotoFolderList } = data
    runInAction(() => {
      this.hotCityIdList = getPhotoFolderList.photoFolderList.map((item: PhotoFolder) => {
        this.photoFolderMap.set(item.id, {
          ...item,
          name: item.tagList.find((i) => i.name === '每日分享') ?
            `${item.name}-${moment(item.createDate).format('YYYYMMDD')}`:
            item.name,
        })
        return item.id
      })
    })
  }

  @action('初始化最新项目列表')
  initFreshIdList = async (tagList: string[], searchString?: string) => {
    const { data } = await gqlClient.query({
      query: GET_PHOTO_FOLDER_LIST,
      variables: {
        tagList,
        searchString,
        sp: 0,
        ps: 6,
        fileSp: 0,
        filePs: -1,
      },
    })
    const { getPhotoFolderList } = data
    runInAction(() => {
      this.freshIdList = getPhotoFolderList.photoFolderList.map((item: PhotoFolder) => {
        this.photoFolderMap.set(item.id, {
          ...item,
          name: item.tagList.find((i) => i.name === '每日分享') ?
            `${item.name}-${moment(item.createDate).format('YYYYMMDD')}`:
            item.name,
        })
        return item.id
      })
    })
  }

  @action('初始化推荐苗木列表')
  initRecommendPhotoFileIdList = async (tagList: string[], searchString?: string) => {
    const { data } = await gqlClient.query({
      query: GET_SEEDLING_PHOTO_FILE_LIST,
      variables: {
        tagList,
        searchString,
        sp: 0,
        ps: 6,
      },
    })
    const { getSeedlingPhotoFileList } = data
    runInAction(() => {
      this.recommendPhotoFileIdList = getSeedlingPhotoFileList.seedlingPhotoFileList.map((item: SeedlingPhotoFile) => {
        this.seedlingPhotoFileMap.set(item.id, item)
        return item.id
      })
    })
  }

  @action('初始化优质项目列表')
  initProjectPhotoFolderIdList = async (tagList: string[], searchString?: string) => {
    const { data } = await gqlClient.query({
      query: GET_PHOTO_FOLDER_LIST,
      variables: {
        tagList,
        searchString,
        sp: 0,
        ps: 6,
        fileSp: 0,
        filePs: -1,
      },
    })
    const { getPhotoFolderList } = data
    runInAction(() => {
      this.projectPhotoFolderCount = getPhotoFolderList.count
      this.projectPhotoFolderIdList = getPhotoFolderList.photoFolderList.map((item: PhotoFolder) => {
        this.photoFolderMap.set(item.id, {
          ...item,
          name: item.tagList.find((i) => i.name === '每日分享') ?
            `${item.name}-${moment(item.createDate).format('YYYYMMDD')}`:
            item.name,
        })
        return item.id
      })
    })
  }

  @action('加载优质项目列表')
  nextProjectPhotoFolderIdList = async (tagList: string[], searchString?: string) => {
    const { data } = await gqlClient.query({
      query: GET_PHOTO_FOLDER_LIST,
      variables: {
        tagList,
        searchString,
        sp: this.projectPhotoFolderIdList.length,
        ps: 6,
        fileSp: 0,
        filePs: -1,
      },
    })
    const { getPhotoFolderList } = data
    runInAction(() => {
      this.projectPhotoFolderCount = getPhotoFolderList.count
      const idList = getPhotoFolderList.photoFolderList.map((item: PhotoFolder) => {
        this.photoFolderMap.set(item.id, {
          ...item,
          name: item.tagList.find((i) => i.name === '每日分享') ?
            `${item.name}-${moment(item.createDate).format('YYYYMMDD')}`:
            item.name,
        })
        return item.id
      })
      this.projectPhotoFolderIdList.push(...idList)
    })
  }

  @action('初始化苗木参考列表')
  initTreePhotoFileIdList = async (tagList: string[], searchString?: string) => {
    const { data } = await gqlClient.query({
      query: GET_SEEDLING_PHOTO_FILE_LIST,
      variables: {
        tagList,
        searchString,
        sp: 0,
        ps: 36,
      },
    })
    const { getSeedlingPhotoFileList } = data
    runInAction(() => {
      this.treePhotoFileCount = getSeedlingPhotoFileList.count
      this.treePhotoFileIdList = getSeedlingPhotoFileList.seedlingPhotoFileList.map((item: SeedlingPhotoFile) => {
        this.seedlingPhotoFileMap.set(item.id, item)
        return item.id
      })
    })
  }

  @action('加载苗木参考列表')
  nextTreePhotoFileIdList = async (tagList: string[], searchString?: string) => {
    const { data } = await gqlClient.query({
      query: GET_SEEDLING_PHOTO_FILE_LIST,
      variables: {
        tagList,
        searchString,
        sp: this.treePhotoFileIdList.length,
        ps: 36,
      },
    })
    const { getSeedlingPhotoFileList } = data
    runInAction(() => {
      this.treePhotoFileCount = getSeedlingPhotoFileList.count
      const idList = getSeedlingPhotoFileList.seedlingPhotoFileList.map((item: SeedlingPhotoFile) => {
        this.seedlingPhotoFileMap.set(item.id, item)
        return item.id
      })
      this.treePhotoFileIdList.push(...idList)
    })
  }

  @action('获取文件下载地址')
  getFileUrl = async (id: string) => {
    const { data, errors } = await gqlClient.query({
      query: GET_PHOTO_FILE_URI,
      variables: {
        id
      },
    })
    if (data) {
      const { fileUrl } = data.getSeedlingPhotoFile
      window.open(`https://${fileUrl}`, "_blank")
    }
    return errors
  }

  @action('获取文件下载地址')
  getSeedlingFileUrl = async (id: string) => {
    const { data, errors } = await gqlClient.query({
      query: GET_SEEDLING_PHOTO_FILE_URI,
      variables: {
        id
      },
    })
    if (data) {
      const { fileUrl } = data.getSeedlingPhotoFile
      window.open(`https://${fileUrl}`, "_blank")
    }
    return errors
  }

  @action('更新用户项目收藏')
  setPhotoFolderCollection = async (id: string, state: boolean) => {
    await gqlClient.mutate({
      mutation: UPDATE_PHOTO_FOLDER_IS_COLLECTION,
      variables: { id, state },
    })
    runInAction(() => {
      const photoFolder = toJS(this.photoFolderMap.get(id))
      if (photoFolder) {
        photoFolder.isCollection = state
        photoFolder.collectionAmount += state ? 1 : -1
        this.photoFolderMap.set(id, photoFolder)
      }
    })
  }

  @action('更新用户项目图片收藏')
  setPhotoFileCollection = async (id: string, state: boolean) => {
    await gqlClient.mutate({
      mutation: UPDATE_PHOTO_FILE_IS_COLLECTION,
      variables: { id, state },
    })
    runInAction(() => {
      const photoFolder = Array.from(this.photoFolderMap.values()).find((v) => {
        const photoFolder = toJS(v)
        return photoFolder ? photoFolder.photoFileList.find((item) => item.id === id) : false
      })
      if (photoFolder) {
        const pf = toJS(photoFolder)
        pf.photoFileList.map((item) => {
          if (item.id === id) {
            item.isCollection = state
            item.collectionAmount += (state ? 1 : -1)
          }
          return item
        })
        this.photoFolderMap.set(pf.id, pf)
      }
    })
  }

  @action('更新用户选苗图片收藏')
  setSeedlingPhotoFileCollection = async(id: string, state: boolean) => {
    await gqlClient.mutate({
      mutation: UPDATE_SEEDLING_PHOTO_FILE_IS_COLLECTION,
      variables: { id, state },
    })
    runInAction(() => {
      const seedlingPhotoFile = toJS(this.seedlingPhotoFileMap.get(id))
      if (seedlingPhotoFile) {
        seedlingPhotoFile.isCollection = state
        seedlingPhotoFile.collectionAmount += state ? 1 : -1
        this.seedlingPhotoFileMap.set(id, seedlingPhotoFile)
      }
    })
  }

  @action('更新弹窗文件显示下标')
  setDialogPhotoFileListIndex = async (id: string) => {
    await gqlClient.query({
      query: GET_PHOTO_FILE,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    })
  }

  @action('更新项目列表')
  addPhotoFolderMap = async (photoFolderList: PhotoFolder[]) => {
    runInAction(() => {
      photoFolderList.forEach((item) => {
        this.photoFolderMap.set(item.id, {
          ...item,
          name: item.tagList.find((i) => i.name === '每日分享') ?
            `${item.name}-${moment(item.createDate).format('YYYYMMDD')}`:
            item.name,
        })
      })
    })
  }

  @action('更新选苗图列表')
  addSeedlingPhotoFileMap = async (seedlingPhotoFileList: SeedlingPhotoFile[]) => {
    runInAction(() => {
      seedlingPhotoFileList.forEach((item) => {
        this.seedlingPhotoFileMap.set(item.id, item)
      })
    })
  }
}
