import {
  withStyles,
  Container,
  Typography,
  Box, createStyles, Grid, Theme, WithStyles, Divider,
} from "@material-ui/core"
import { inject, observer } from "mobx-react"
import React from "react"
import { PageProps, PhotoFile, Stores } from "../types"
import ProjectLargePaper from "../components/projectLargePaper"
import PhotoDialog from "../components/photoDialog"
import { PhotoPaper } from "../components/photoPaper"
import SelectMenu from "../components/selectMenu"
import PhotoFolderStore from "../stores/photoFolderStore"
import PhotoSearch from "../components/photoSearch"
import FindInfiniteScroll from "./findPage/findInfiniteScroll"
import AccountStore from "../stores/accountStore"

interface Option {
  key: string,
  text: string,
  value: string,
}

type TagKey = '全部项目' | '全部分类' | '全国地区'

type SearchPhotoType = '优质项目' | '苗木参考'

interface SelectedStores {
  photoFolderStore: PhotoFolderStore
  accountStore: AccountStore,
}

interface Props extends SelectedStores, PageProps, WithStyles<typeof styles> {}

interface States {
  searchString: string,
  tagObject: {
    [K in SearchPhotoType]: {
      [K in TagKey]?: string
    }
  }
  searchPhotoType: SearchPhotoType
  dialogState: boolean,
  dialogList: PhotoFile[]
  dialogIndex: number
}

const styles = (theme: Theme) => createStyles({
  root: {
    flex: 1,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  searchIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginLeft: theme.spacing(2)
  },
  scrollTooltip: {
    margin: theme.spacing(6, 0),
    textAlign: 'center',
  },
})

@inject((stores: Stores): SelectedStores => ({
  photoFolderStore: stores.photoFolderStore,
  accountStore: stores.accountStore,
}))
@observer
class FindPage extends React.Component<Props> {

  state: States = {
    searchString: '',
    searchPhotoType: '优质项目',
    tagObject: { '优质项目': {}, '苗木参考': {} },
    dialogState: false,
    dialogList: [],
    dialogIndex: 0,
  }

  componentDidMount() {
    const { photoFolderStore } = this.props
    const { searchPhotoType } = this.state
    if (searchPhotoType === '优质项目') {
      photoFolderStore.initProjectPhotoFolderIdList([])
    }
    if (searchPhotoType === '苗木参考') {
      photoFolderStore.initTreePhotoFileIdList([])
    }
  }

  handleChangeSearchString = (e: any) => {
    this.setState({ searchString: e.target.value })
  }

  handleTagOption = (e: any, searchPhotoType: SearchPhotoType, tagKey: TagKey, tag: Option) => {
    const { searchString, tagObject } = this.state
    const { photoFolderStore } = this.props
    tagObject[searchPhotoType][tagKey] = tag.value
    const tagList = [...Object.values(tagObject[searchPhotoType])].filter((i): i is string => !!i)
    if (searchPhotoType === '优质项目') {
      photoFolderStore.initProjectPhotoFolderIdList(tagList, searchString)
    }
    if (searchPhotoType === '苗木参考') {
      photoFolderStore.initTreePhotoFileIdList(tagList, searchString)
    }
    this.setState({ tagObject })
  }

  handleTruningPhotoDialog = (nextValue: number) => {
    const { photoFolderStore } = this.props
    const { dialogIndex, dialogList } = this.state
    const photoFile = dialogList[dialogIndex + nextValue]
    if (photoFile) {
      photoFolderStore.setDialogPhotoFileListIndex(photoFile.id)
      this.setState({
        dialogIndex: dialogIndex + nextValue,
      })
    }
  }

  handleSearch = (searchPhotoType: SearchPhotoType) => {
    const { searchString, tagObject } = this.state
    const { photoFolderStore } = this.props
    const tagList = [...Object.values(tagObject[searchPhotoType])].filter((i): i is string => !!i)
    if (searchPhotoType === '优质项目') {
      photoFolderStore.initProjectPhotoFolderIdList(tagList, searchString)
    }
    if (searchPhotoType === '苗木参考') {
      photoFolderStore.initTreePhotoFileIdList(tagList, searchString)
    }
    this.setState({ searchPhotoType })
  }

  handleFetchMoreData = () => {
    const { searchString, searchPhotoType, tagObject } = this.state
    const { photoFolderStore } = this.props
    const tagList = Object.values(tagObject[searchPhotoType]).filter((i) => i)
    if (searchPhotoType === '优质项目') {
      photoFolderStore.nextProjectPhotoFolderIdList(tagList as string[], searchString)
    }
    if (searchPhotoType === '苗木参考') {
      photoFolderStore.nextTreePhotoFileIdList(tagList as string[], searchString)
    }
  }

  handleCloseProjectPhotoDialog = () => {
    this.setState({ dialogState: false, dialogList: [] })
  }

  handlePhotoFolderDialog = (e: any, id: string, index: number) => {
    const { photoFolderStore } = this.props
    const photoFolder = photoFolderStore.projectPhotoFolder.find((item) => (item?.id === id))
    if (photoFolder) {
      this.setState({
        dialogList: photoFolder.photoFileList.map((item) => {
          item.name = photoFolder.name
          return item
        }),
        dialogIndex: index,
        dialogState: true,
      })
    }
  }

  handleSeedlingPhotoFileDialog = (e: any, v: string) => {
    const { photoFolderStore } = this.props
    const photoFileIndex = photoFolderStore.treePhotoFile.findIndex((item) => {
      return item?.id === v
    })
    if (photoFolderStore.treePhotoFile.length) {
      this.setState({
        dialogList: photoFolderStore.treePhotoFile,
        dialogIndex: photoFileIndex,
        dialogState: true,
      })
    }
  }

  handleCollection = (e: any, id: string, state: boolean) => {
    const { dialogList } = this.state
    const { photoFolderStore } = this.props
    photoFolderStore.setPhotoFileCollection(id, state)
    this.setState({ dialogList: dialogList.map((i) => {
      if (i.id === id) {
        i.isCollection = state
        i.collectionAmount += (state ? 1 : -1)
      }
      return i
    }) })
  }

  handleCollectionSeedling = (e: any, id: string, state: boolean) => {
    const { dialogList } = this.state
    const { photoFolderStore } = this.props
    photoFolderStore.setSeedlingPhotoFileCollection(id, state)
    dialogList.forEach((item) => {
      if (item.id === id) {
        item.isCollection = state
        item.collectionAmount += state ? 1 : -1
      }
    })
    this.setState({ dialogList })
  }

  handleDownload = async (e: any, id: string) => {
    const { photoFolderStore } = this.props
    return await photoFolderStore.getFileUrl(id)
  }

  handleDownloadSeedling = async (e: any, id: string) => {
    const { photoFolderStore } = this.props
    return await photoFolderStore.getSeedlingFileUrl(id)
  }

  render() {
    const {
      searchString,
      searchPhotoType,
      tagObject,
      dialogIndex,
      dialogList,
      dialogState,
    } = this.state
    const { classes, photoFolderStore, accountStore } = this.props
    const { projectPhotoFolder, projectPhotoFolderCount, treePhotoFileCount, treePhotoFile } = photoFolderStore
    return (
      <Box className={ classes.root }>
        <Box paddingTop={ 6 } paddingBottom={ 2 }>
          <Container maxWidth="md">
            <PhotoSearch actionList={ [
                { key: '优质项目', text: '优质项目', value: '优质项目', onClick: () => this.handleSearch('优质项目') },
                { key: '苗木参考', text: '苗木参考', value: '苗木参考', onClick: () => this.handleSearch('苗木参考') },
              ] }
              value={ searchString }
              onChange={ this.handleChangeSearchString }
            />
          </Container>
        </Box>
        {
          searchPhotoType === '优质项目' &&
          <>
            <Container maxWidth="xl">
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <SelectMenu
                    value={ tagObject['优质项目']['全部分类'] || '' }
                    onChange={ (e, v) => this.handleTagOption(e, '优质项目', '全部分类', v) }
                    data={ [
                      { key: '全部分类',  text: '全部分类', value: '' },
                      { key: '优质项目',  text: '优质项目', value: '优质项目' },
                      { key: '每日分享',  text: '每日分享', value: '每日分享' },
                    ] }
                  />
                </Box>
              </Box>
              <Divider className={ classes.divider } />
              <FindInfiniteScroll
                dataLength={ projectPhotoFolder.length }
                next={ this.handleFetchMoreData }
                hasMore={ projectPhotoFolder.length < projectPhotoFolderCount }
                empty= {
                  <Box marginTop={ 6 } marginBottom={ 6 } textAlign="center">
                    <Typography variant="h2" gutterBottom color="textSecondary">
                      没有找到相关项目
                    </Typography>
                  </Box>
                }
              >
                <Grid container direction="column" spacing={ 2 } justify={ projectPhotoFolder.length ? "flex-start" : "center"}>
                  {
                    projectPhotoFolder.map((item, index) => (
                      <ProjectLargePaper
                        key={ index }
                        value={ item }
                        onClick={ this.handlePhotoFolderDialog }
                      />
                    ))
                  }
                </Grid>
              </FindInfiniteScroll>
            </Container>
            <PhotoDialog
              accountStore={ accountStore }
              onDownload={ this.handleDownload }
              onNext={ () => this.handleTruningPhotoDialog(1) }
              onPrev={ () => this.handleTruningPhotoDialog(-1) }
              onClose={ this.handleCloseProjectPhotoDialog }
              open={ dialogState }
              onCollection={ this.handleCollection }
              value={ {
                index: dialogIndex,
                list: dialogList,
              } }
            />
          </>
        }
        {
          searchPhotoType === '苗木参考' &&
          <>
            <Container maxWidth="xl">
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <SelectMenu
                    value={ tagObject['苗木参考']['全国地区'] || '' }
                    onChange={ (e, v) => this.handleTagOption(e, '苗木参考', '全国地区', v) }
                    data={ [
                      { key: '苗木种类',  text: '苗木种类', value: '' },
                      { key: '乔木',  text: '乔木', value: '乔木' },
                      { key: '观赏草',  text: '观赏草', value: '观赏草' },
                      { key: '花境植物',  text: '花境植物', value: '花境植物' },
                    ] }
                  />
                </Box>
              </Box>
              <Divider className={ classes.divider } />
              <FindInfiniteScroll
                dataLength={ treePhotoFile.length }
                next={ this.handleFetchMoreData }
                hasMore={ treePhotoFile.length < treePhotoFileCount }
                empty={
                  <Box marginTop={ 6 } marginBottom={ 6 } textAlign="center">
                    <Typography variant="h2" gutterBottom color="textSecondary">
                      没有找到相关苗木
                    </Typography>
                  </Box>
                }
              >
                <Grid container spacing={ 2 } justify={ treePhotoFile.length ? "flex-start" : "center"}>
                  {
                    treePhotoFile.map((item, index) => (
                      <Grid item key={ index } xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 } xl={ 2 }>
                        <PhotoPaper
                          value={ item }
                          onClick={ this.handleSeedlingPhotoFileDialog }
                        />
                      </Grid>
                    ))
                  }
                </Grid>
              </FindInfiniteScroll>
            </Container>
            <PhotoDialog
              accountStore={ accountStore }
              onDownload={ this.handleDownloadSeedling }
              onNext={ () => this.handleTruningPhotoDialog(1) }
              onPrev={ () => this.handleTruningPhotoDialog(-1) }
              onClose={ this.handleCloseProjectPhotoDialog }
              open={ dialogState }
              onCollection={ this.handleCollectionSeedling }
              value={ {
                index: dialogIndex,
                list: dialogList,
              } }
            />
          </>
        }
      </Box>
    )
  }
}

export default withStyles(styles)(FindPage)
