import { Box, Card, CardActionArea, Paper, Typography } from "@material-ui/core"
import React from "react"
import { PhotoFolder } from "../types"

interface Props {
  value: PhotoFolder | undefined,
  onClick: (e: any, id: string, v: number) => void,
}

export default class ProjectPaper extends React.Component<Props> {

  render() {
    const { value, onClick } = this.props
    if (!value || value.photoFileList.length < 2) {
      return (<Box p={ 1 }></Box>)
    }
    const {
      id,
      name,
      photoFileList,
      note,
    } = value

    return (
      <Box p={ 1 }>
        <Paper elevation={ 0 }>
          <Box width={ 1 } paddingTop='120%'
            style={ {
              position: 'relative',
            } }
          >
            {
              photoFileList.length > 1 ?
              <>
                <Card style={{
                  position: 'absolute',
                  width: '92%',
                  height: '92%',
                  top: 0,
                  right: 0,
                }}>
                  <CardActionArea
                    onClick={ (e) => onClick(e, id, 1)}
                    style={ {
                      backgroundSize: 'cover',
                      backgroundColor: '#00000055',
                      backgroundBlendMode: 'multiply',
                      borderRadius: 1,
                      width: '100%',
                      height: '100%',
                    } }
                  >
                    <img
                      style={ {
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        display: 'block',
                      } }
                      loading="lazy"
                      alt="图片加载失败"
                      src={`https://${photoFileList[1].previewUrl}?x-oss-process=image/resize,l_600`}
                    />
                  </CardActionArea>
                </Card>
                <Card style={{
                  position: 'absolute',
                  width: '92%',
                  height: '92%',
                  bottom: 0,
                  left: 0,
                }}>
                  <CardActionArea
                    onClick={ (e) => onClick(e, id, 0)}
                    style={ {
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      width: '100%',
                      height: '100%',
                    } }
                  >
                    <img
                      style={ {
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        display: 'block',
                      } }
                      loading="lazy"
                      alt="图片加载失败"
                      src={`https://${photoFileList[0].previewUrl}?x-oss-process=image/resize,l_600`}
                    />
                  </CardActionArea>
                </Card>
              </> :
              <CardActionArea
                onClick={ (e) => onClick(e, id, 0)}
                style={ {
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  bottom: 0,
                  left: 0,
                  backgroundImage: `url("https://${photoFileList[0].previewUrl}?x-oss-process=image/resize,l_600")`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                } }
              />
            }
          </Box>
          <Box m={ 1 } display="flex">
            <Box flexGrow={ 1 }>
              <Typography align="left" variant="subtitle1" component="p">{ name }</Typography>
            </Box>
          </Box>
          <Box m={ 1 }>
            <Typography align="left" variant="body1">{ note }</Typography>
          </Box>
        </Paper>
      </Box>
    )
  }
}