import moment from 'moment'

export const dateToString = (date: Date, format: string = 'YYYY-MM-DD HH:mm:ss') => {
  return moment(date).format(format)
}

export const getUrlParam = (param: string): { [x: string]: string } => {
  const searchParams = new URLSearchParams(window.location.search)
  return {
    [`${param}`]: searchParams.get(param) || ''
  }
}

export const getUrlParams = (params: string[]): { [x: string]: string } => {
  const searchParams = new URLSearchParams(window.location.search)
  const data: { [x: string]: string } = {}
  params.forEach((param: string) => {
    data[`${param}`] = searchParams.get(param) || ''
  })
  return data
}

export const setUrlParams = (params: { [x: string]: string }): string => {
  const searchParams = new URLSearchParams()
  Object.keys(params).forEach(key => {
    searchParams.set(key, params[key])
  })
  return searchParams.toString()
}


export const chinaProvince = [
  '北京',
  '天津',
  '上海',
  '重庆',
  '香港',
  '澳门',
  '河北',
  '山西',
  '内蒙古',
  '辽宁',
  '吉林',
  '黑龙江',
  '江苏',
  '浙江',
  '安徽',
  '福建',
  '江西',
  '山东',
  '河南',
  '湖北',
  '湖南',
  '广东',
  '广西',
  '海南',
  '四川',
  '贵州',
  '云南',
  '西藏',
  '陕西',
  '甘肃',
  '青海',
  '宁夏',
  '新疆',
  '台湾',
]
