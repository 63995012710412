import { Typography, Button, Box, Container, createStyles, FormControl, TextField, Theme, WithStyles, withStyles, Stepper, Step, StepLabel, FormHelperText, Link, Snackbar, IconButton } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import { Form, FormikProps, withFormik } from "formik"
import React from "react"
import * as Yup from 'yup'
import moment from 'moment'
import { gqlClient } from "../main/graphql"
import { SEND_SMS, UPDATE_PASSWORD, VALIDATE_SMS } from "../service/accountService"
import SMSTextField from "./smsTextField"

const styles = (theme: Theme) => createStyles({
  textAlign: {
    textAlign: 'center',
  },
  smsCodeInput: {
    marginTop: theme.spacing(6),
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: theme.spacing(8),
    },
  },
  stepper: {
    marginTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
  },
  smsCodeFontSize: {
    fontSize: theme.spacing(5),
    textAlign: 'center',
  },
})

interface FormProps {
  stepIndex: number,
  onNextPage: () => void,
  onJump: () => void,
}

interface FormValues {
  phone: string,
  password: string,
  smsCode: string,
  smsCode0: string,
  smsCode1: string,
  smsCode2: string,
  smsCode3: string,
}

interface Props extends FormProps, WithStyles<typeof styles>, FormikProps<FormValues> {}

interface States {
  snackBarMessage: string,
  snackBarState: boolean,
}

class ResetPasswordFormik extends React.Component<Props, States> {

  state = {
    snackBarMessage: '',
    snackBarState: false,
  }

  handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { handleChange } = this.props
    e.target.value = ''
    handleChange(e)
  }

  handleInputSMSCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { setFieldValue } = this.props
    setFieldValue(e.target.name, e.target.value)
  }

  handleResendSMS = async () => {
    const phone = this.props.getFieldProps('phone').value
    const { errors } = await gqlClient.mutate({
      mutation: SEND_SMS,
      variables: { phone, category: 'resetPassword' },
    })
    if (errors) {
      this.setState({
        snackBarMessage: errors[0].message,
        snackBarState: true,
      })
    } else {
      this.setState({
        snackBarMessage: '验证码已发送',
        snackBarState: true,
      })
    }
  }

  handleSnackBarClose = () => {
    this.setState({ snackBarState: false })
  }

  handleFinish = () => {
    this.props.onJump()
  }

  render() {
    const { snackBarState, snackBarMessage } = this.state
    const {
      classes,
      children,
      values,
      touched,
      errors,
      handleChange,
      isSubmitting,
      stepIndex,
    } = this.props
    return (
      <Container maxWidth="xs">
        <Box
          component={ Form }
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          { children }
          {
            (() => {
              switch (stepIndex) {
                case 0:
                return (
                  <>
                    <FormControl margin="normal" fullWidth>
                      <TextField
                        name="phone"
                        label="手机号"
                        type="text"
                        autoComplete="username"
                        variant="filled"
                        value={ values.phone }
                        onChange={ handleChange }
                        error={ touched.phone && Boolean(errors.phone) }
                        helperText={ (touched.phone && errors.phone) || ' ' }
                      />
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={ isSubmitting }
                      >
                        验证手机号
                      </Button>
                    </FormControl>
                  </>
                )
                case 1:
                return (
                  <>
                    <FormControl className={ classes.smsCodeInput } margin="normal">
                      <Box marginBottom={ 2 }>
                        <SMSTextField
                          values={ [{
                            name: 'smsCode0',
                            value: values.smsCode0,
                            touched: touched.smsCode0,
                            error: errors.smsCode0,
                          }, {
                            name: 'smsCode1',
                            value: values.smsCode1,
                            touched: touched.smsCode1,
                            error: errors.smsCode1,
                          }, {
                            name: 'smsCode2',
                            value: values.smsCode2,
                            touched: touched.smsCode2,
                            error: errors.smsCode2,
                          }, {
                            name: 'smsCode3',
                            value: values.smsCode3,
                            touched: touched.smsCode3,
                            error: errors.smsCode3,
                          }] }
                          autoFocus
                          variant="outlined"
                          onChange={ this.handleInputSMSCode }
                          onFocus={ this.handleInputFocus }
                          InputProps={ {
                            classes: { input: classes.smsCodeFontSize },
                          } }
                        >
                          <FormHelperText
                            className={ classes.textAlign }
                            error={ touched.smsCode && Boolean(errors.smsCode) }
                          >
                            { (touched.smsCode && errors.smsCode) || ' ' }
                          </FormHelperText>
                        </SMSTextField>
                      </Box>
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={ isSubmitting }
                      >
                        确认
                      </Button>
                    </FormControl>
                    <FormControl className={ classes.textAlign } margin="normal" fullWidth>
                      <Link onClick={ this.handleResendSMS }>没有收到短信</Link>
                    </FormControl>
                  </>
                )
                case 2:
                return (
                  <>
                    <FormControl margin="normal" fullWidth>
                      <TextField
                        name="password"
                        label="密码"
                        type="password"
                        autoComplete="current-password"
                        variant="filled"
                        value={ values.password }
                        onChange={ handleChange }
                        error={ touched.password && Boolean(errors.password) }
                        helperText={ (touched.password && errors.password) || ' ' }
                      />
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={ isSubmitting }
                      >
                        提交
                      </Button>
                    </FormControl>
                  </>
                )
                case 3:
                return (
                  <>
                    <Box marginTop={ 4 } marginBottom={ 4 }>
                      <Typography>您的密码已经重制</Typography>
                    </Box>
                    <FormControl margin="normal" fullWidth>
                      <Button
                        onClick={ this.handleFinish }
                        variant="contained"
                        color="primary"
                        size="large"
                      >
                        登陆账号
                      </Button>
                    </FormControl>
                  </>
                )
                default:
                return (
                  <>参数错误，请联系管理员</>
                )
              }
            })()
          }
        </Box>
        <Stepper className={ classes.stepper } activeStep={ stepIndex }>
          <Step completed={ 0 < stepIndex }>
            <StepLabel>确认手机</StepLabel>
          </Step>
          <Step completed={ 1 < stepIndex }>
            <StepLabel>验证</StepLabel>
          </Step>
          <Step completed={ 2 < stepIndex }>
            <StepLabel>重制密码</StepLabel>
          </Step>
        </Stepper>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={ snackBarState }
          autoHideDuration={ 6000 }
          onClose={ this.handleSnackBarClose }
          message={ snackBarMessage }
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={ this.handleSnackBarClose }>
                <Close fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Container>
    )
  }
}

const Mik = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    phone: '',
    password: '',
    smsCode: '',
    smsCode0: '',
    smsCode1: '',
    smsCode2: '',
    smsCode3: '',
  }),
  validationSchema: (props: FormProps) => [
    Yup.object().shape({
      phone: Yup.string().required('请输入手机号').matches(/^\d{11}$/, '手机号码不正确'),
    }),
    Yup.object().shape({
      smsCode0: Yup.string().required('请输入验证码').min(1, '超过最小值').max(1, '超过最大值').matches(/^[0-9]+$/, "请输入数字"),
      smsCode1: Yup.string().required('请输入验证码').min(1, '超过最小值').max(1, '超过最大值').matches(/^[0-9]+$/, "请输入数字"),
      smsCode2: Yup.string().required('请输入验证码').min(1, '超过最小值').max(1, '超过最大值').matches(/^[0-9]+$/, "请输入数字"),
      smsCode3: Yup.string().required('请输入验证码').min(1, '超过最小值').max(1, '超过最大值').matches(/^[0-9]+$/, "请输入数字"),
    }),
    Yup.object().shape({
      password: Yup.string()
        .required('请创建密码')
        .matches(/^(?=.*[a-z])/, '密码必须包含小写字母')
        .matches(/^(?=.*[A-Z])/, '密码必须包含大写字母')
        .matches(/^(?=.*[0-9])/, '密码必须包含数字')
        .min(8, '密码过短'),
    }),
  ][props.stepIndex] || Yup.object().shape({}),
  handleSubmit: async (values, { props, setSubmitting, setErrors, setTouched }) => {
    if (props.stepIndex === 0) {
      const { errors } = await gqlClient.mutate({
        mutation: SEND_SMS,
        variables: { phone: values.phone, category: 'resetPassword' },
      })
      if (errors) {
        setErrors({ phone: errors[0].message })
      } else {
        setSubmitting(false)
        setTouched({})
        props.onNextPage()
      }
    }
    if (props.stepIndex === 1) {
      const smsCode = [
        values.smsCode0,
        values.smsCode1,
        values.smsCode2,
        values.smsCode3,
      ].join('')
      const { errors } = await gqlClient.mutate({
        mutation: VALIDATE_SMS,
        variables: { phone: values.phone, smsCode, category: 'resetPassword' },
      })
      if (errors) {
        setErrors({ smsCode: errors[0].message })
      } else {
        setSubmitting(false)
        setTouched({})
        props.onNextPage()
      }
    }
    if (props.stepIndex === 2) {
      const smsCode = [
        values.smsCode0,
        values.smsCode1,
        values.smsCode2,
        values.smsCode3,
      ].join('')
      const { data, errors } = await gqlClient.mutate({
        mutation: UPDATE_PASSWORD,
        variables: { phone: values.phone, password: values.password, smsCode },
      })
      if (errors) {
        setErrors({ password: errors[0].message })
      } else {
        localStorage.setItem('token', data.updatePassword.token)
        localStorage.setItem('tokenDate', moment().format())
        localStorage.setItem('refreshToken', data.updatePassword.refreshToken)
        setSubmitting(false)
        setTouched({})
        props.onNextPage()
      }
    }
  }
})(withStyles(styles)(ResetPasswordFormik))

export default class ResetPasswordFrom extends React.Component<
  { onJump: () => void },
  { stepIndex: number }
> {

  state = { stepIndex: 0 }

  handleNextPage = () => {
    this.setState({ stepIndex: this.state.stepIndex + 1})
  }

  render() {
    const { stepIndex } = this.state
    const { children } = this.props
    return (
      <Mik
        stepIndex={ stepIndex }
        onNextPage={ this.handleNextPage }
        { ...this.props }
      >{ children }</Mik>
    )
  }
}
