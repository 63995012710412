import { Box, Container, Typography, ListItem } from "@material-ui/core"
import React from "react"

export default class PrivacyPolicyPage extends React.Component {
  render() {
    return (
      <Container maxWidth="md">
        <Box marginTop={ 4 }>
          <Typography gutterBottom variant="h5" align="center">
            隐私协议
          </Typography>
          <Typography gutterBottom align="right">更新时间：2021年7月5日</Typography>
          <br/>

          <Typography gutterBottom>
            欢迎使用EZpic（包含相应的网络web支持）。
          </Typography>
          <Typography gutterBottom>
            EZpic由上海魏玛软件技术服务有限公司（以下简称“魏玛软件”）开发。
            确保用户的数据安全和隐私保护是魏玛软件的首要任务，
            本政策适用于EZpic产品及服务，包括EZpic官网（域名为ezpic.com.cn），
            载明了您访问和使用魏玛软件的产品和服务时所收集的数据及其处理方式。
            如魏玛软件关联公司的产品或服务中使用了EZpic提供的产品或服务但未设独立的隐私政策的，
            则本政策同样适用于该部分产品或服务。
          </Typography>
          <Typography gutterBottom>
            需要特别说明的是，本隐私政策不适用于其他第三方通过前述网页或其他客户端直接向您提供的服务
            （统称“第三方服务”），如您通过EZpic服务绑定个人支付业务以实现的支付、
            转账、红包等支付相关功能，均由第三方服务提供者向您提供服务。
            您向该第三方服务提供者提供的信息不适用于本政策，
            您在选择使用第三服务前应充分了解第三方服务的产品功能及隐私保护政策，
            再选择是否开通功能。
          </Typography>
          <Typography gutterBottom>
            在使用魏玛软件产品或服务前，请您务必仔细阅读并透彻理解本政策，
            在确认充分理解并同意后使用相关产品或服务。
            一旦您开始使用魏玛软件产品或服务，即表示您已充分理解并同意本政策。
          </Typography>
          <br/>

          <Typography gutterBottom variant="h6">
            一、个人信息的收集
          </Typography>
          <Typography variant="subtitle1">
            在您使用魏玛软件产品时，魏玛软件需要/可能需要收集和使用的您的个人信息包括如下两种：
          </Typography>
          <ListItem>
            <Typography>
              1、为实现向您提供魏玛软件产品及/或服务的基本功能，您须授权魏玛软件收集、使用的必要的信息。
              如您拒绝提供相应信息，您将无法正常使用魏玛软件的产品及/或服务；
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              2、为实现向您提供魏玛软件产品及/或服务的附加功能，您可选择授权魏玛软件收集、使用的信息。
              如您拒绝提供，您将无法正常使用相关附加功能或无法达到魏玛软件拟达到的功能效果，
              但并不会影响您正常使用魏玛软件产品及/或服务的基本功能；
            </Typography>
          </ListItem>
          <Typography variant="subtitle1">
            您理解并同意：
          </Typography>
          <ListItem>
            <Typography>
              1、魏玛软件致力于打造多样的产品和服务以满足您的需求。
              因魏玛软件向您提供的产品和服务种类众多，且不同用户选择使用的具体产品/服务范围存在差异，
              相应的，基本/附加功能及收集使用的个人信息类型、范围等会有所区别，
              请以具体的产品/服务功能为准；
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              2、为给您带来更好的产品和服务体验，魏玛软件在持续努力改进技术，
              随之魏玛软件可能会不时推出新的或优化后的功能，可能需要收集、
              使用新的个人信息或变更个人信息使用目的或方式。
              对此，魏玛软件将通过更新本政策、弹窗、页面提示等方式另行向您说明对应信息的收集目的、
              范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。
              在此过程中，如果您有任何疑问、意见或建议的，您可通过网页提供的各种联系方式与魏玛软件联系，
              魏玛软件会尽快为您作出解答。
            </Typography>
          </ListItem>
          <Typography variant="subtitle1">
            您向魏玛软件提供的信息：
          </Typography>
          <ListItem>
            <Typography>
              魏玛软件将记录您提供的相关个人信息，
              如：姓名、手机号码等，上述个人信息是您获得魏玛软件提供的服务的基础。
            </Typography>
          </ListItem>
          <Typography variant="subtitle1">
            使用魏玛软件服务过程中收集信息:
          </Typography>
          <ListItem>
            <Typography>
              基于优化用户体验的目的，魏玛软件会获取与提升魏玛软件服务有关的其他信息，
              例如当您访问魏玛软件时，魏玛软件可能会收集那些服务的受欢迎程度、
              浏览器软件信息等以便优化魏玛软件的服务。
            </Typography>
          </ListItem>
          <br/>

          <Typography gutterBottom variant="h6">
            二、未成年人保护
          </Typography>
          <Typography gutterBottom>
            如您为儿童，魏玛软件要求您请您的父母或其他监护人仔细阅读本隐私权政策，
            并在征得您的父母或其他监护人同意的前提下使用魏玛软件的服务或向魏玛软件提供信息。
          </Typography>
          <Typography gutterBottom>
            对于经父母或其他监护人同意使用魏玛软件的产品或服务而收集儿童个人信息的情况，
            魏玛软件只会在法律法规允许、父母或其他监护人明确同意或者保护儿童所必要的情况下使用、
            共享、转让或披露此信息。
          </Typography>
          <Typography gutterBottom>
            若您是儿童的父母或其他监护人，
            请您关注您监护的儿童是否是在取得您的授权同意之后使用魏玛软件的服务。
            如您对您所监护的儿童的个人信息有疑问，请通过网页下方联系方式与魏玛软件联系。
          </Typography>
          <br/>

          <Typography gutterBottom variant="h6">
            三、个人数据使用
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            魏玛软件不会与服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：
          </Typography>
          <ListItem>
            <Typography>
              1、在获取您同意的情况下共享：获得您的明确同意后，魏玛软件会与其他方共享您的个人信息；
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              2、在法定情形下的共享：魏玛软件可能会根据法律法规规定、诉讼、争议解决需要，
              或按行政、司法机关依法提出的要求，对外共享您的个人信息；
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              3、您主动选择情况下的共享：只有共享您的个人信息、个人敏感信息，才能提供您所要求的第三方产品和服务。如：
              <ListItem>
                <Typography>
                  1）支付功能。在您通过魏玛软件提供的功能实现收付、
                  转账、红包等支付相关功能时，您同意魏玛软件向支付平台提供您的个人基础信息
                  （UserID、头像、昵称等信息）及身份信息（姓名）以便开通或绑定支付宝账号；
                  您同意在上述支付功能开通期间，授权魏玛软件获得您登录账号所绑定的支付宝账户信息
                  （含昵称、头像、账户名），否则您将无法使用收付、转账、红包等支付相关功能。
                  您在使用支付平台的支付相关服务时，需阅读并遵守协议和规则；
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  2）为使魏玛软件及时获悉并确认您的支付进度及状态，为您提供售后与争议解决服务，
                  您同意魏玛软件可自您所选择的金融机构处收集与支付进度相关信息；
                </Typography>
              </ListItem>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              4、侵权投诉处置：在您被他人投诉侵犯知识产权或其他合法权利时，
              需要向投诉人披露您的必要资料，以便进行投诉处理；
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              5、与关联公司间共享：为便于魏玛软件基于服务账号共同向您提供服务，
              推荐您可能感兴趣的信息，识别账号异常，
              保护魏玛软件关联公司或其他用户或公众的人身财产安全免遭侵害，
              您的个人信息可能会与魏玛软件的关联公司共享。魏玛软件只会共享必要的个人信息
              （如为便于您使用魏玛软件关联公司产品或服务，魏玛软件会向关联公司共享您必要的账号信息），
              如果魏玛软件共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，
              将再次征求您的授权同意；
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              6、与授权合作伙伴共享：仅为实现本隐私政策中声明的目的，
              魏玛软件的某些服务将由魏玛软件和授权合作伙伴共同提供。
              魏玛软件仅会出于合法、正当、必要、特定、明确的目的共享您的信息，
              授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的，
              除非另行获得您的明示同意除外。对于涉及儿童个人信息的，魏玛软件不允许合作伙伴进行转委托；
            </Typography>
          </ListItem>
          <br/>
          <Typography gutterBottom variant="subtitle1">
            魏玛软件不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
          </Typography>
          <ListItem>
            <Typography>
              1、在获取明确同意的情况下转让：获得您的明确同意后，魏玛软件会向其他方转让您的个人信息；
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              2、在魏玛软件服务提供者发生合并、收购或破产清算情形，
              或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，
              魏玛软件会要求新的持有您个人信息的公司、组织继续受本政策的约束，
              否则魏玛软件将要求该公司、组织和个人重新向您征求授权同意；
            </Typography>
          </ListItem>
          <br/>
          <Typography gutterBottom variant="subtitle1">
            魏玛软件仅会在以下情况下，公开披露您的个人信息：
          </Typography>
          <ListItem>
            <Typography>
              1、获得您明确同意或基于您的主动选择，魏玛软件可能会公开披露您的个人信息；
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              2、如果魏玛软件确定您出现违反法律法规或严重违反相关协议规则的情况，
              或为保护魏玛软件及其关联公司用户或公众的人身财产安全免遭侵害，
              魏玛软件可能依据法律法规或相关协议规则征得您同意的情况下披露关于您的个人信息，
              包括相关违规行为以及魏玛软件已对您采取的措施；
            </Typography>
          </ListItem>
          <Typography gutterBottom variant="subtitle1">
            以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
          </Typography>
          <ListItem>
            <Typography>
              1、与国家安全、国防安全有关的；
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              2、与公共安全、公共卫生、重大公共利益有关的；
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              3、与犯罪侦查、起诉、审判和判决执行等有关的；
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              5、您自行向社会公众公开的个人信息；
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
            </Typography>
          </ListItem>
          <Typography gutterBottom>
            请您注意，根据法律规定，共享、转让经匿名化处理的个人信息，
            且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、
            转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
          </Typography>
          <br/>

          <Typography gutterBottom variant="h6">
            四、您的个人信息如何在全球范围转移
          </Typography>
          <Typography gutterBottom>
            魏玛软件将在中华人民共和国境内运营服务中所收集和产生的个人信息存储在中华人民共和国境内。
            就中华人民共和国境外用户使用服务及服务过程中产生的个人信息，
            您同意回传存储在中华人民共和国境内服务器上，否则您无法使用现有的产品及服务。
            此类情况下，魏玛软件会确保您的个人信息安全得到足够同等的保护。
          </Typography>
          <br/>

          <Typography gutterBottom variant="h6">
            五、如何使用 Cookie 和同类技术
          </Typography>
          <Typography gutterBottom>
            为使您获得更轻松的访问体验，您使用魏玛软件产品或服务时，
            魏玛软件可能会通过采用各种技术收集和存储您访问魏玛软件服务的相关数据，
            在您访问或再次访问魏玛软件服务时,魏玛软件能识别您的身份，并通过分析数据为您提供更好更多的服务。
            包括使用小型数据文件识别您的身份，这么做是为了解您的使用习惯，
            帮您省去重复输入账户信息的步骤，或者帮助判断您的账户安全。
            这些数据文件可能是Cookie、Flash Cookie，
            或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。
          </Typography>
          <Typography gutterBottom>
            请您理解，魏玛软件的某些服务只能通过使用Cookie才可得到实现。
            如果您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝魏玛软件的Cookie，
            但拒绝魏玛软件的Cookie在某些情况下您可能无法使用依赖于cookies的魏玛软件服务的部分功能。
          </Typography>
          <br/>

          <Typography gutterBottom variant="h6">
            六、本政策如何更新
          </Typography>
          <Typography gutterBottom>
            魏玛软件的隐私政策可能变更。未经您明确同意魏玛软件不会限制您按照本隐私政策所应享有的权利。
            魏玛软件会在网页上公告发布对本隐私政策所做的任何变更。
            对于重大变更，魏玛软件还会提供更为显著的通知（包括魏玛软件会不限于提供弹窗提示等方式进行意见征集、
            公告通知）。
          </Typography>
          <Typography variant="subtitle1">
            本政策所指的重大变更包括但不限于：
          </Typography>
          <ListItem>
            <Typography>
              1、魏玛软件的服务模式发生重大变化。如处理个人信息的目的、处理个人信息的类型以及个人信息的使用方式等；
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              2、魏玛软件在控制权、组织架构等方面发生重大变化。如并购重组等引起的控制者变更等；
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              3、个人信息共享、转让或公开披露的主要对象发生变化；
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              4、您参与个人信息处理方面的权利及其行使方式发生重大变化；
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              5、魏玛软件负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时；
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              6、用户信息安全影响评估报告表明存在高风险时。
            </Typography>
          </ListItem>
          <br/>

        </Box>
      </Container>
    )
  }
}
