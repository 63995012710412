import {
  withStyles, Button, createStyles, Divider,
  IconButton, InputAdornment, TextField, Theme,
  Typography, WithStyles,
  Link, Snackbar, Box,
} from "@material-ui/core";
import { Visibility, VisibilityOff, Close } from "@material-ui/icons";
import { Form, FormikProps, withFormik } from "formik";
import React from "react";
import * as Yup from 'yup'
import { gqlClient } from "../main/graphql";
import { SEND_SMS, UPDATE_PASSWORD } from "../service/accountService";

const styles = (theme: Theme) => createStyles({
  divider: {
    margin: theme.spacing(2, 0),
  },
  sendSMS: {
    marginLeft: theme.spacing(2),
  },
})

interface FormProps {
  phone: string,
}

interface FormValues {
  password: string,
  smsCode: string,
}

interface Props extends WithStyles<typeof styles>, FormikProps<FormValues>, FormProps {}

interface States {
  showPassword: boolean,
  showSnackBar: boolean,
  snackBarMessage: string,
}

class AccountResetPasswordForm extends React.Component<Props, States> {
  state = {
    showPassword: false,
    showSnackBar: false,
    snackBarMessage: '',
  }

  handleClickShowPassword = () => {
    const { showPassword } = this.state
    this.setState({
      showPassword: !showPassword,
    })
  }

  handleResendSMS = async () => {
    const { phone } = this.props
    const { errors } = await gqlClient.mutate({
      mutation: SEND_SMS,
      variables: { phone, category: 'resetPassword' },
    })
    if (errors) {
      this.setState({
        snackBarMessage: errors[0].message,
        showSnackBar: true,
      })
    } else {
      this.setState({
        snackBarMessage: '验证码已发送',
        showSnackBar: true,
      })
    }
  }

  handleSnackBarClose = () => {
    this.setState({ showSnackBar: false })
  }

  render () {
    const { showPassword, showSnackBar, snackBarMessage } = this.state
    const { classes, handleChange, values, touched, errors, handleSubmit } = this.props
    return (
      <Box component={ Form }>
        <Typography variant="h6" gutterBottom>重置密码</Typography>
        <Divider className={ classes.divider } variant="middle" />
        <Typography variant="body1" gutterBottom>
          重置密码需要短信验证
          <Link className={ classes.sendSMS } onClick={ this.handleResendSMS }>发送短信验证码</Link>
        </Typography>
        <TextField
          name="password"
          label="密码"
          type={ showPassword ? "text" : "password" }
          variant="filled"
          fullWidth={ true }
          value={ values.password }
          onChange={ handleChange }
          error={ touched.password && Boolean(errors.password) }
          helperText={ (touched.password && errors.password) || ' ' }
          InputProps={ {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={ this.handleClickShowPassword }
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          } }
        />
        <TextField
          name="smsCode"
          label="验证码"
          type={ "text" }
          variant="filled"
          value={ values.smsCode }
          onChange={ handleChange }
          fullWidth={ true }
          error={ touched.smsCode && Boolean(errors.smsCode) }
          helperText={ (touched.smsCode && errors.smsCode) || ' ' }
        />
        <Button variant="contained" color="primary" onClick={ () => handleSubmit() }>提交密码</Button>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={ showSnackBar }
          autoHideDuration={ 6000 }
          onClose={ this.handleSnackBarClose }
          message={ snackBarMessage }
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={ this.handleSnackBarClose }>
                <Close fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Box>
    )
  }
}

export default withFormik<FormProps, FormValues>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    password: '',
    smsCode: '',
  }),
  validationSchema: (props: FormProps) => (
    Yup.object().shape({
      password: Yup.string()
        .required('请创建密码')
        .matches(/^(?=.*[a-z])/, '密码必须包含小写字母')
        .matches(/^(?=.*[A-Z])/, '密码必须包含大写字母')
        .matches(/^(?=.*[0-9])/, '密码必须包含数字')
        .min(8, '密码过短'),
      smsCode: Yup.string().required('请输入验证码').min(4, '输入过短').max(4, '输入过长').matches(/^[0-9]+$/, "请输入数字"),
    })
  ),
  handleSubmit: async (values, { props, resetForm, setFieldValue, setErrors }) => {
    const { errors } = await gqlClient.mutate({
      mutation: UPDATE_PASSWORD,
      variables: { phone: props.phone, password: values.password, smsCode: values.smsCode },
    })
    if (errors) {
      setErrors({ smsCode: errors[0].message })
    } else {
      setFieldValue('password', '')
      setFieldValue('smsCode', '')
      resetForm()
    }
  },
})(withStyles(styles)(AccountResetPasswordForm))
